import axios from "axios";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { ERROR_TYPES } from "../context/reducers/errorReducers";
import colors from "./colors";

const tools = {
  setAuthToken: (token) => {
    if (token) {
      // Apply to every request
      axios.defaults.headers.common["Authorization"] = token;
    } else {
      // Delete Auth header
      delete axios.defaults.headers.common["Authorization"];
    }
  },
  isEmpty: (value) => {
    return (
      value === undefined ||
      value === null ||
      !value ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0)
    );
  },

  truncate: (text, n) => {
    return text.length > n ? text.substr(0, n - 1) + "..." : text;
  },

  filterError: (errors, dispatch = null, type) => {
    var errorData = {};
    if (errors.response) {
      var status = errors.response.status;

      if (status == 404) {
        toast.error("L'entité n'a pas été trouvée (erreur 404)");
      }

      if (errors.response.data) {
        if (errors.response.data.other) {
          toast.warning("Erreur : " + errors.response.data.other);
          errorData = errors.response.data;
        } else {
          errorData = errors.response.data;
        }
      }
    } else {
      toast.error("Erreur interne : " + errors);
    }

    if (dispatch) {
      dispatch({ type: type, payload: false });
      dispatch({ type: ERROR_TYPES.GET_ERRORS, payload: errorData });
    }
    return errorData;
  },
  filterAlert: (type, message) => {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "info":
        toast.info(message);
        break;

      default:
        break;
    }
  },
  getState: (page, initial) => {
    var ls = localStorage.getItem(page);

    if (ls) {
      return JSON.parse(ls);
    }
    return initial;
  },

  saveState: (page, name, value) => {
    var ls = localStorage.getItem(page);
    if (ls) {
      ls = JSON.parse(ls);
    } else {
      ls = {};
    }
    ls[name] = value;

    localStorage.setItem(page, JSON.stringify(ls));
  },
  // getLatLngFromAddress: async (address) => {
  //   Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
  //   return new Promise((resolve, reject) => {
  //     Geocode.fromAddress(address).then(
  //       (response) => {
  //         const { lat, lng } = response.results[0].geometry.location;
  //         resolve({ lat: lat, lng: lng });
  //       },
  //       (error) => {
  //         reject(error);
  //       }
  //     );
  //   });
  // },
  parse_query_string: (query) => {
    var vars = query.split("&");
    var query_string = {};
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      var key = decodeURIComponent(pair[0]);
      var value = decodeURIComponent(pair[1]);
      // If first entry with this name
      if (typeof query_string[key] === "undefined") {
        query_string[key] = decodeURIComponent(value);
        // If second entry with this name
      } else if (typeof query_string[key] === "string") {
        var arr = [query_string[key], decodeURIComponent(value)];
        query_string[key] = arr;
        // If third or later entry with this name
      } else {
        query_string[key].push(decodeURIComponent(value));
      }
    }
    return query_string;
  },
  getFullname: (person) => {
    return person
      ? (person.gender == 1 ? "Mr" : "Mme") +
          " " +
          person.firstname +
          " " +
          person.lastname
      : "";
  },
  getFullAddress: (location) => {
    return location ? `${location.name} ${location.postalCode}` : "";
  },
  formatFollowingPattern: (value, separation, separator) => {
    value = value ? value + "" : "";
    var nirconcat = value.split(separator).join("");

    var newnir = "";
    var curr = 0;
    for (var key in separation) {
      var ns = separation[key] + curr;
      if (curr < nirconcat.length) {
        newnir += nirconcat.substring(curr, ns);
        var newnirsp = newnir.split(separator);
        if (
          newnirsp[newnirsp.length - 1].length == separation[key] &&
          newnirsp.length < separation.length
        ) {
          newnir += separator;
        }
      }

      curr = ns;
    }
    if (newnir.substr(newnir.length - separator.length) == separator) {
      newnir = newnir.substring(0, newnir.length - separator.length);
    }
    return newnir;
  },
  formatPhone: (value) => {
    return tools.formatFollowingPattern(value, [4, 2, 2, 2], " ");
  },
  formatDate: (value) => {
    return value ? DateTime.fromISO(value).toFormat("dd/MM/yyyy") : "";
  },
  findIn: (arrayOfObjects, attribute, value) => {
    var ref = arrayOfObjects.find((o) => o[attribute] == value);
    return ref || {};
  },
  getCERElementUpdateTypeColor: (updateType) => {
    switch (updateType) {
      case "ADDED":
        return colors.success;

      case "DELETED":
        return colors.danger;
      default:
        return "#7f7f7f";
    }
  },
  getGender: (gender) => {
    return gender == 1 ? "Mr" : "Mme";
  },
  getCERElementUpdateTypeStyle: (updateType) => {
    switch (updateType) {
      case "ADDED":
        return {
          color: tools.getCERElementUpdateTypeColor(updateType),
        };
      case "DELETED":
        return {
          color: tools.getCERElementUpdateTypeColor(updateType),
          textDecoration: "line-through",
        };
      default:
        return {
          color: tools.getCERElementUpdateTypeColor(updateType),
        };
    }
  },
};

export default tools;
