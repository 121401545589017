const colors = {
  primary: "#0e6bb0",
  success: "#0b9a10",
  info: "#9c27b0",
  danger: "#ec6b70",
  warning: "#ff9800",
  dark: "#222222",
  secondary: "#ffffff",
  light: "#efefef",
  body: "#f3f3f3",
  progress: "#27afb0",
};

export default colors;
