import React, { useState, useEffect } from "react";
import Control from "../../common/Control";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Axios from "axios";
import { api_url } from "../../../config";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import LandingCD from "./LandingCD";
import LandingPE from "./LandingPE";
import LandingML from "./LandingML";
import RdspRecap from "./RdspRecap";
import tools from "../../../helpers/tools";

const initialDays = [
  { id: 1, name: "Lundi", morning: true, evening: false },
  { id: 2, name: "Mardi", morning: true, evening: false },
  { id: 3, name: "Mercredi", morning: true, evening: false },
  { id: 4, name: "Jeudi", morning: true, evening: false },
  { id: 5, name: "Vendredi", morning: true, evening: false },
  { id: 6, name: "Samedi", morning: true, evening: false },
];
const initialMoments = [
  { id: 1, name: "Matin" },
  { id: 2, name: "Après-midi" },
];

const FormRdv = ({
  rdspId,
  userData,
  formData,
  setRdvTaken = false,
  hasDroit,
  isConjoint,
}) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const [organism, setOrganism] = useState(false);
  const [sites, setSites] = useState(false);
  const [rdvs, setRdvs] = useState(false);
  const [site, setSite] = useState(false);
  const [rdv, setRdv] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [formRdv, setFormRdv] = useState(false);
  const [countSel, setCountSel] = useState(0);
  const [displayAllRdv, setDisplayAllRdv] = useState(false);
  const [timeSelected, setTimeSelected] = useState({
    days: [],
  });
  const [days, setDays] = useState(initialDays);
  const [rdvsLoading, setRdvsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDl, setShowDl] = useState(false);

  var qs = window.location.search.substring(1);
  qs = tools.parse_query_string(qs);
  var origin = "";
  if (qs.o) {
    origin = qs.o;
  }

  useEffect(() => {
    if (hasDroit && rdspId) {
      Axios.get(api_url + "rdsp/" + rdspId + "/orientation")
        .then((res) => {
          setOrganism(res.data.organization);
          setSites(res.data.sites);
          if (res.data.sites.length == 1) {
            setSite(res.data.sites[0].id);
          }
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        });
    }
  }, [hasDroit]);
  useEffect(() => {
    if (site) {
      setRdv(false);
      setDisplayAllRdv(false);
      setRdvsLoading(true);
      Axios.post(api_url + "slotcalendars/sites/" + site, {
        userRecipientId: userData.id,
      })
        .then((res) => {
          setRdvs(res.data);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setRdvsLoading(false);
        });
    }
  }, [site]);

  useEffect(() => {
    if (rdvs && rdvs.length > 0) {
      reCalcDays();
      setRdv(rdvs[0].id);
    }
  }, [rdvs]);

  useEffect(() => {
    if (countSel >= 3) {
      setRdv(false);
      setDisplayAllRdv(true);
    }
  }, [countSel]);

  useEffect(() => {
    setRdv(false);
  }, [timeSelected]);

  function reCalcDays() {
    var dys = JSON.parse(JSON.stringify(days));
    dys = dys.filter((d) => {
      if (
        rdvs.find((r) => {
          var daystart = DateTime.fromISO(r.start).weekday;
          if (daystart == d.id) {
            return true;
          }
        })
      ) {
        return d;
      }
    });
    setDays(dys);
  }

  function save() {
    if (Object.keys(errors).length === 0 && errors.constructor === Object) {
      var selRdv = rdvs.find((r) => r.id == rdv);
      selRdv.comment = message;
      setIsLoading(true);
      Axios.patch(api_url + "slotcalendars/recipients/" + userData.id, selRdv)
        .then((res) => {
          setFormRdv({
            rdv: selRdv,
            site: site,
          });
          toast.success("Votre RDV a bien été enregistré");
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsLoading(false);
        });
      //save
      // setFormRdv({
      //   site: 1,
      //   rdv: 2,
      // });
    }
  }
  function setNextRdv() {
    var currentIndex = null;
    rdvs.forEach((r, rk) => {
      if (r.id == rdv) {
        currentIndex = rk;
      }
    });
    if (currentIndex + Math.floor((rdvs.length - 1) / 2) > rdvs.length - 1) {
      currentIndex = 0;
    } else {
      currentIndex += Math.floor((rdvs.length - 1) / 2);
    }
    window.$("#rdv").removeClass("fadeInRight").addClass("fadeOutLeft");
    setTimeout(() => {
      window.$("#rdv").removeClass("fadeOutLeft").addClass("fadeInRight");

      setRdv(rdvs[currentIndex].id);
      setCountSel(countSel + 1);
    }, 200);
  }
  function updateTimeSelected(e) {
    var { name, value } = e.target;
    var ev = JSON.parse(JSON.stringify(timeSelected));

    if (ev[name].includes(value)) {
      ev[name] = ev[name].filter((v) => v != value);
    } else {
      ev[name].push(value);
    }

    setTimeSelected(ev);
  }

  return (
    <>
      {!hasDroit ? (
        <div className="row">
          <div className="col-12 col-md-8 col-lg-6 m-auto text-center">
            <div className="card">
              <div className="card-body">
                <i className="far fa-check-circle text-success fa-3x mb-3 animated  rotateIn delay-1s slow" />
                <p className="animated fadeIn delay-1s">
                  Vous avez enregistré les données nécessaires à la mise en
                  oeuvre de votre droit à l'accompagnement lié au bénéfice du
                  RSA.{" "}
                </p>
                <p className="mb-0 animated fadeIn delay-1s">
                  Votre demande sera traitée dans les meilleurs délais et en
                  fonction de votre situation, vous recevrez par mail les
                  informations utiles pour poursuivre vos démarches
                </p>
              </div>
            </div>
          </div>
          {formData && <RdspRecap datas={formData} />}
        </div>
      ) : !organism || isLoading ? (
        Object.keys(errors).length ? (
          <div className="alert alert-info">{errors.other}</div>
        ) : rdspId ? (
          <Loader text="Chargement des informations" />
        ) : (
          <div className="text-center">
            <Link className="btn btn-primary" to="/">
              Accéder à mon espace
            </Link>
          </div>
        )
      ) : organism.slugName == "CD" ? (
        <LandingCD />
      ) : organism.slugName == "PE" ? (
        <LandingPE />
      ) : organism.slugName == "ML_NORD" ? (
        <LandingML url="https://missionlocalenord.re/preinscription" />
      ) : organism.slugName == "ML_SUD" ? (
        <LandingML url="https://missionlocalesud.re/contactez-nous/" />
      ) : organism.slugName == "ML_EST" ? (
        <LandingML url="https://docs.google.com/forms/d/e/1FAIpQLSewC4h9nEpJa2kQc-NAfnR4lDjndczNNMLLK1c-UPeNJeB3DA/viewform" />
      ) : organism.slugName == "ML_OUEST" ? (
        <LandingML url="https://mio-reunion.re/preinscription-a-la-mio/" />
      ) : (
        <div className="row">
          <div className="col-12 col-md-8 col-lg-8 m-auto">
            {!formRdv ? (
              <div className="card overflow-hidden">
                <div className="card-body p-4">
                  <div className="text-center">
                    <img src="/assets/logo.jpeg" height="50" />

                    <p className="mt-3">
                      Vous allez bénéficier d'un accompagnement personnalisé de
                      la <strong>CAF</strong>
                    </p>
                    <p className="text-danger mb-5">
                      Nous vous remercions de prendre rendez-vous à l'aide du
                      formulaire ci-dessous pour maintenir le versement de votre
                      RSA.
                    </p>
                  </div>
                  {sites && (
                    <Control
                      label="Choisissez un lieu pour votre rendez-vous"
                      type="select"
                      name="site"
                      datas={sites}
                      dataIndex={"id"}
                      dataLabel={"name"}
                      value={site}
                      change={(e) => setSite(e.target.value)}
                      error={errors}
                    />
                  )}
                  {rdvsLoading && (
                    <Loader text="Chargement des rendez-vous disponibles" />
                  )}
                  {site && rdvs.length ? (
                    displayAllRdv ? (
                      <>
                        <Control
                          label="Quels jours de la semaine êtes-vous disponible ?"
                          type="checkBtnList"
                          btnInline={true}
                          name="days"
                          datas={days}
                          itemClassName={"btn-sm"}
                          value={timeSelected.days}
                          change={updateTimeSelected}
                        />

                        {timeSelected.days.length > 0 && (
                          <Control
                            label="Choisissez la date de rendez-vous"
                            type="btnList"
                            datas={rdvs.filter((r) => {
                              var timestart = DateTime.fromISO(r.start).weekday;

                              if (timeSelected.days.includes(timestart))
                                return true;
                              return false;
                            })}
                            dataIndex="id"
                            dataLabel="label"
                            name="rdv"
                            change={(e) => setRdv(e.target.value)}
                            value={rdv}
                          />
                        )}
                      </>
                    ) : rdv ? (
                      <>
                        <div className="form-group animated fadeInUp faster my-5">
                          <label className="d-block">
                            Choix de la date de rendez-vous
                          </label>
                          <div className="btn-group">
                            <button
                              className="btn btn-default active animated faster fadeInRight"
                              style={{ animationDuration: "200ms" }}
                              id="rdv"
                            >
                              <p style={{ width: "93%", margin: 0 }}>
                                {rdvs.find((r) => r.id == rdv).label}
                              </p>
                              <i
                                className="fa fa-check ml-2 animated fadeInRight faster "
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>

                        <button
                          className="btn btn-link w-100 animated fadeInUp faster"
                          style={{ marginTop: -40 }}
                          onClick={setNextRdv}
                        >
                          Choisir une autre date de rendez-vous
                        </button>
                      </>
                    ) : null
                  ) : !rdvsLoading ? (
                    <div className="alert alert-info">
                      Aucun rendez-vous disponible pour le moment
                    </div>
                  ) : null}
                  {rdv && (
                    <>
                      <Control
                        className="animated fadeInUp faster"
                        label="Souhaitez-vous laisser un message à la personne qui vous accueillera ?"
                        name="message"
                        value={message}
                        change={(e) => setMessage(e.target.value)}
                        error={errors}
                      />
                      {Object.keys(errors).length > 0 && (
                        <span className="text-danger animated flash">
                          {Object.values(errors)}
                        </span>
                      )}
                      {rdv && (
                        <button
                          onClick={save}
                          className="btn btn-success w-100 animated fadeInUp faster"
                        >
                          Valider ce rendez-vous{" "}
                          {rdvs.find((r) => r.id == rdv).label}
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="card animated fadeInRight mb-5">
                  <div className="card-header">
                    <h1 className="mb-0">Votre rendez-vous</h1>
                  </div>
                  <div className="card-body p-4 p-lg-5 p-md-4">
                    <div className="row mb-4">
                      <div className="col-12 col-lg-6 mb-3">
                        Vous avez RDV le :
                      </div>
                      <div className="col-12 col-lg-6 font-weight-bold text-center">
                        {formRdv.rdv.label}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-lg-6 mb-3">Lieu du RDV :</div>
                      <div className="col-12 col-lg-6 font-weight-bold text-center">
                        {sites.find((s) => s.id == formRdv.site).name} -{" "}
                        {sites.find((s) => s.id == formRdv.site).address}{" "}
                        {
                          sites.find((s) => s.id == formRdv.site).location
                            .postalCode
                        }{" "}
                        {sites.find((s) => s.id == formRdv.site).location.name}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 col-lg-6 mb-3">
                        Informations complémentaires :
                      </div>
                      <div className="col-12 col-lg-6 font-weight-bold text-center">
                        <p>
                          Merci de vous munir de votre PASS'ORIENTATION
                          disponible dans votre espace
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {!state.auth.isAuthenticated && (
                  <div className="animated fadeInUp delay-1s">
                    {formData &&
                      [1, 2].includes(
                        formData.rdsp.find((rd) => rd.question == 16).value
                      ) &&
                      userData.origin !== "SID" &&
                      !isConjoint && (
                        <div className="row mb-3">
                          <div className="col-12 col-lg-6 mb-3">
                            Vous avez un conjoint ? Merci de remplir le
                            questionnaire le concernant
                          </div>
                          <div className="col-12 col-lg-6">
                            <a
                              href={`/rdsp${
                                origin.length
                                  ? "?o=" + origin + "&co=1"
                                  : "?co=1"
                              }`}
                              target="_BLANK"
                              className="btn btn-default w-100"
                            >
                              Saisir le second questionnaire...
                            </a>
                          </div>
                        </div>
                      )}
                    <div className="row mb-3">
                      <div className="col-12 col-lg-6 mb-3">
                        Dès à présent suivez votre parcours RSA au sein de la
                        CAF de la Réunion sur votre espace allocataire E-SPOAR
                      </div>
                      <div className="col-12 col-lg-6">
                        <Link
                          to="/"
                          onClick={setRdvTaken ? setRdvTaken : () => {}}
                          className="btn btn-success w-100"
                        >
                          Mon espace E-SPOAR
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {errors.other && (
            <div className="col-12 mt-3">
              <p className="alert alert-danger">{errors.other}</p>
            </div>
          )}
        </div>
      )}

      {/* {formData &&
        [1, 2].includes(formData.rdsp.find((rd) => rd.question == 16).value) &&
        userData.origin !== "SID" &&
        !isConjoint && (
          <div className="d-center text-center mt-3 col-12 flex-column">
            <div className="mb-3">
              Vous avez un conjoint ? Merci de remplir le questionnaire le
              concernant
            </div>
            <div className="mx-auto col-lg-6">
              <a
                href={`/rdsp${
                  origin.length ? "?o=" + origin + "&co=1" : "?co=1"
                }`}
                target="_BLANK"
                className="btn btn-default w-100"
              >
                Saisir le second questionnaire...
              </a>
            </div>
          </div>
        )} */}
    </>
  );
};

export default FormRdv;
