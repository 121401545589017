import React from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import { DateTime } from "luxon";
import Colors from "../../../color-theme.scss";

const TimelineEvent = ({ event }) => {
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{
        background: "#fff",
        color: "#404040",
        padding: 11,
        paddingTop: 4,
        boxShadow:
          "0 -3px 0 " + Colors[event.color] + " ,0 0 15px -3px #afafaf",
      }}
      contentArrowStyle={{ borderRight: "7px solid " + Colors[event.color] }}
      date={DateTime.fromISO(event.date).toFormat("dd/MM/yyyy")}
      iconStyle={{
        background: Colors[event.color],
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 30,
        height: 30,
      }}
      icon={<i className={"fa fa-1x fa-" + event.icon} />}
    >
      <span
        style={{ background: Colors[event.color] }}
        className="badge text-white font-weight-normal"
      >
        {event.type}
      </span>
      <h3
        style={{ fontSize: "1rem", color: Colors[event.color] }}
        className="vertical-timeline-element-title"
      >
        {event.title}
      </h3>
      {event.description != "" && (
        <p
          className="mt-2"
          style={{ fontSize: "0.8rem", lineHeight: "0.9rem" }}
        >
          {event.description}
        </p>
      )}
    </VerticalTimelineElement>
  );
};

export default TimelineEvent;
