import React from "react";
import { ScaleLoader } from "react-spinners";

const Loader = ({
  style = {},
  color = "#0e6bb0",
  height = 25,
  width = 3,
  overlay = false,
  text = false,
}) => {
  var sty = style;
  if (overlay) {
    sty.position = "absolute";
    sty.height = "100%";
    sty.background = "#ffffff73";
    sty.zIndex = "99";
    sty.top = 0;
  }
  return (
    <div
      style={sty}
      className="d-flex justify-content-center w-100 align-items-center flex-column"
    >
      <ScaleLoader color={color} height={height} width={width} />
      {text && <div>{text}</div>}
    </div>
  );
};

export default Loader;
