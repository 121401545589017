import React from "react";

const Modal = (props) => {
  return (
    <div
      className="modal fade"
      id={props.id}
      tabIndex="-1"
      role="dialog"
      aria-labelledby={props.id + "Label"}
      aria-hidden="true"
    >
      <div
        className="modal-dialog"
        role="document"
        style={
          props.large
            ? {
                maxWidth: 800,
              }
            : {}
        }
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={props.id + "Label"}>
              {props.title}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">{props.children}</div>
          <div className="modal-footer">
            {props.actions}
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
