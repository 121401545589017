import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { DateTime } from "luxon";
import React from "react";
import tools from "../../../helpers/tools";
import useCERModule from "../../../hooks/useCERModule/useCERModule";

const ContractInfo = ({ CER_DURATIONS }) => {
  const { recipientCER } = useCERModule();
  var styles = StyleSheet.create({
    container: {
      paddingTop: 10,
      paddingBottom: 10,
    },
    text: {
      fontSize: 9,
    },
  });

  const signDate =
    recipientCER.status == "DRAFT"
      ? ""
      : recipientCER.signedAt
      ? DateTime.fromISO(recipientCER.signedAt).setLocale("FR").toFormat("dd LLLL yyyy")
      : null;

  const lastSignDate = recipientCER.signUpdatedAt
    ? tools.formatDate(recipientCER.signUpdatedAt)
    : "";

  return (
    <View style={{ ...styles.container }}>
      <Text style={{ ...styles.text }}>
        Durée du contrat :{" "}
        {DateTime.fromISO(recipientCER.beginAt).setLocale("FR").toFormat("dd LLLL yyyy")} au{" "}
        {DateTime.fromISO(recipientCER.endAt).setLocale("FR").toFormat("dd LLLL yyyy")} soit{" "}
        {tools.findIn(CER_DURATIONS || [], "id", recipientCER.duration).name}
      </Text>
      <Text style={{ ...styles.text }}>Date de signature : {signDate}</Text>
      {lastSignDate ? (
        <Text style={{ ...styles.text }}>
          Signature des dernières modifications le : {lastSignDate}
        </Text>
      ) : null}
    </View>
  );
};

export default ContractInfo;
