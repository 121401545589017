import React from "react";

const InfoBlock = (props) => {
  return (
    <div className="d-flex justify-content-start flex-row align-items-center">
      {props.children}
    </div>
  );
};

export default InfoBlock;
