import React from "react";

const ProgressSection = ({ sections }) => {
  return (
    <div className="container-fluid">
      <div className="row breadcrumb-custom">
        {sections.map((s, sk) => (
          <div
            key={"brc-" + sk}
            className={"col text-center " + (s.active ? "active" : "")}
          >
            <span>{s.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressSection;
