import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { DateTime } from "luxon";
import dataService from "../../../helpers/dataService";
import Loader from "../../common/Loader";
import tools from "../../../helpers/tools";
//import montserrat from "./montserrat.ttf";

const MyDoc = ({ datas }) => {
  // Font.register({
  //   family: "Montserrat",
  //   format: "truetype",
  //   fonts: [{ src: montserrat }],
  // });
  const {
    educationLevels,
    workSituations,
    workContracts,
    accompanies,
    drivePermits,
    transports,
  } = datas.config;
  // const educationLevels = [
  //   { value: 1, label: "Jamais scolarisé(e)" },
  //   { value: 2, label: "Arrêt scolarité avant 16 ans" },
  //   { value: 3, label: "Arrêt scolarité à partir de 16 ans" },
  //   { value: 4, label: "CAP/BEP" },
  //   { value: 5, label: "BAC ou équivalent" },
  //   { value: 6, label: "BAC +2" },
  //   { value: 7, label: "BAC +3 et plus" },
  // ];

  // const workSituations = [
  //   { value: 1, label: "Salarié(e)" },
  //   { value: 2, label: "Exploitant(e) agricole" },
  //   { value: 3, label: "Travailleur(se) indépendant(e) ou auto-entrepreneur" },
  // ];

  // const workContracts = [
  //   { value: 1, label: "Travail occasionnel et/ou à temps partiel" },
  //   { value: 2, label: "Pas d'activité mais a déjà travaillé" },
  //   { value: 3, label: "Pas d'activité et n'a jamais travaillé" },
  // ];

  // const drivePermits = [
  //   { value: 1, label: "Aucun" },
  //   { value: 2, label: "B = Voiture" },
  //   { value: 3, label: "A1 ou 1 = Moto" },
  //   { value: 4, label: "C = Camion" },
  //   { value: 5, label: "EC = Camion + Remorque" },
  //   { value: 6, label: "D = Autocar, bus" },
  //   { value: 7, label: "ED = D + remorque" },
  // ];
  // const transports = [
  //   { value: 1, label: "Aucun" },
  //   { value: 2, label: "Vélo" },
  //   { value: 3, label: "Scooter, moto" },
  //   { value: 4, label: "Voiture" },
  //   { value: 5, label: "Transports en commun" },
  //   { value: 6, label: "Covoiturage" },
  // ];

  // const accompanies = [
  //   { value: 1, label: "Le logement" },
  //   { value: 2, label: "La santé" },
  //   {
  //     value: 3,
  //     label:
  //       "L'organisation de la vie familiale (garde d'enfant…proche, éloignement d'un proche ou d'un conjoint)",
  //   },
  //   {
  //     value: 4,
  //     label:
  //       "La mobilité (pas de voiture personnelle, manque de transport en commun, ...)",
  //   },
  //   {
  //     value: 5,
  //     label: "La gestion du budget (difficultés financières, dettes, ...)",
  //   },
  //   { value: 6, label: "La compréhension de la langue française" },
  //   { value: 7, label: "La lecture et/ou l'écriture" },
  //   {
  //     value: 8,
  //     label: "Pas de difficulté dans ces domaines",
  //     isExclusive: true,
  //   },
  // ];
  var styles = StyleSheet.create({
    title: {
      fontSize: 18,
    },
    subtitle: {
      fontSize: 14,
      color: "#0e6bb0",
      marginBottom: 3,
      marginTop: 5,
      textTransform: "uppercase",
      borderLeft: "4pt solid #0e6bb0",
      paddingLeft: 10,
    },
    text: {
      fontSize: 9,
    },
    textSmall: {
      fontSize: 7,
    },
    border: {
      border: "1pt solid black",
    },
    legalText: {
      fontSize: 7,
      textAlign: "center",
    },
  });

  return (
    <Document>
      <Page wrap size="A4" orientation="portrait" style={{ padding: "20px" }}>
        <View
          style={{
            position: "absolute",
            top: 10,
            left: 10,
          }}
        >
          <Image src="/assets/logo-po.jpg" style={{ height: 70, width: 103 }} />
        </View>

        <View
          style={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
        >
          <Image
            src="/assets/logo-espoar.png"
            style={{ height: 78, width: 54 }}
          />
        </View>
        <View>
          <Text style={{ textAlign: "center", fontSize: 13 }}>
            Revenu de Solidarité Active
          </Text>
          <Text style={{ textAlign: "center", fontSize: 24, marginBottom: 10 }}>
            PASS Orientation RSA
          </Text>
          <View style={{ textAlign: "center" }}></View>
        </View>
        <View style={{ color: "lightgrey" }}>
          <Text style={{ ...styles.legalText }}>
            Vu les articles 18 à 23 de la loi n°2007-1233 du 21 août 2007 en
            faveur du travail, de l'emploi et du pouvoir d'achat
          </Text>
          <Text style={{ ...styles.legalText }}>
            Vu la loi n°2008-1249 du 1er décembre 2008 généralisant le revenu de
            solidarité active et réformant les politiques d'insertion
          </Text>
          <Text style={{ ...styles.legalText }}>
            Vu le décret n°2009-404 du 15 avril 2009 relatif au revenu de
            solidarité active
          </Text>
          <Text style={{ ...styles.legalText }}>
            Vu le décret n°2009-716 du 18 juin 2009 relatif au traitement
            automatisé de données à caractère personnel
          </Text>
          <Text style={{ ...styles.legalText }}>
            Vu la loi n°78-17 du 06/01/1978 modifiée relative à l'informatique,
            aux fichiers et aux libertés
          </Text>
          <Text style={{ ...styles.legalText }}>
            Vu le décret n°2012-294 du 1er mars 2012 relatif aux procédures
            d'orientation, de suspension et de radiation applicables aux
            bénéficiaires du RSA
          </Text>
        </View>
        <View style={{ marginTop: 10, padding: 5 }}>
          <Text style={{ ...styles.subtitle }}>Ce que vous devez savoir :</Text>
          <Text style={{ ...styles.text, paddingTop: 5 }}>
            La signature d'un contrat relatif à votre projet d'insertion vous
            permet de bénéficier d'un accompagnement pour réaliser vos démarches
            et de maintenir le versement de votre RSA.
          </Text>
          <Text style={{ ...styles.text, paddingTop: 5 }}>
            Le présent document tient lieu de contrat d'orientation vers un
            organisme référent ou un opérateur pour approfondir ou préciser la
            définition de vos besoins préalablement à l'élaboration d'un
            contrat.
          </Text>
          <Text style={{ ...styles.text, paddingTop: 5 }}>
            La loi garantit un droit d'accès et de rectification pour les
            données personnelles recueillies dans le présent document.
          </Text>
        </View>
        <View style={{ padding: 5, marginTop: 5 }}>
          <Text style={{ ...styles.subtitle }}>
            Nom de l'agent CAF :{" "}
            <Text style={{ color: "#00487d" }}>{datas.recipient.cafAgent}</Text>
          </Text>
        </View>
        <View style={{ padding: 5, marginTop: 5 }}>
          <Text style={{ ...styles.subtitle }}>
            Le bénéficiaire :{" "}
            <Text style={{ color: "#00487d" }}>
              {datas.recipient.gender == "1" ? "Mr" : "Mme"}{" "}
              {datas.recipient.firstname}{" "}
              {datas.recipient.lastname.toUpperCase()}
            </Text>
          </Text>

          <Text style={{ ...styles.text }}>
            Né(e) le :{" "}
            <Text style={{ color: "#00487d" }}>
              {datas.recipient.birthdate &&
                DateTime.fromISO(datas.recipient.birthdate).toFormat(
                  "dd/MM/yyyy"
                )}
            </Text>
          </Text>
          <Text style={{ ...styles.text }}>
            Email :{" "}
            <Text style={{ color: "#00487d" }}>{datas.recipient.email}</Text>
          </Text>
          <Text style={{ ...styles.text }}>
            Téléphone :{" "}
            <Text style={{ color: "#00487d" }}>{datas.recipient.phone}</Text>
          </Text>
          <Text style={{ ...styles.text }}>
            N° allocataire :{" "}
            <Text style={{ color: "#00487d" }}>{datas.recipient.CAFId}</Text>
          </Text>
        </View>
        <View style={{ padding: 5, marginTop: 5 }}>
          <View>
            <Text style={{ ...styles.subtitle }}>
              La situation professionnelle actuelle du bénéficiaire :
            </Text>
            <Text
              style={{
                fontSize: 11,
                paddingBottom: 5,
              }}
            >
              Inscrit(e) à France Travail :{" "}
              <Text style={{ color: "#00487d" }}>
                {datas.recipient.isPE
                  ? "OUI - n° d'inscription : " + datas.recipient.numSubscribePE
                  : "NON"}
              </Text>
            </Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  ...styles.border,
                  width: "80%",
                  fontSize: 12,
                  padding: 3,
                  textAlign: "center",
                }}
              >
                Emplois recherchés
              </Text>
              <Text
                style={{
                  ...styles.border,
                  width: "20%",
                  fontSize: 11,
                  padding: 3,
                  textAlign: "center",
                }}
              >
                Durée expérience
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text
                style={{
                  ...styles.border,
                  width: "80%",
                  fontSize: 9,
                  padding: 3,
                  height: 70,
                }}
              >
                <Text style={{ color: "#00487d" }}>
                  {datas.recipient.jobSearch}
                </Text>
              </Text>
              <Text
                style={{
                  ...styles.border,
                  width: "20%",
                  fontSize: 12,
                  padding: 3,
                }}
              >
                <Text style={{ color: "#00487d" }}>
                  {datas.recipient.jobSearchExp}
                </Text>
              </Text>
            </View>
            {/* <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 5,
              }}
            >
              <Text
                style={{
                  ...styles.border,
                  width: "80%",
                  fontSize: 9,
                  padding: 3,
                }}
              >
                Autre emploi recherché : {datas.recipient.job2Search}
              </Text>
              <Text
                style={{
                  ...styles.border,
                  width: "20%",
                  fontSize: 12,
                  padding: 3,
                }}
              >
                {" "}
                {datas.recipient.job2SearchExp}
              </Text>
            </View> */}
          </View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View
              style={{
                width: "25%",
                padding: 3,
              }}
            >
              <Text
                style={{
                  fontSize: 9,
                  marginBottom: 3,
                }}
              >
                Niveau de formation
              </Text>
              {educationLevels.map((ed, edk) => (
                <Text key={"ed" + edk} style={{ ...styles.textSmall }}>
                  {datas.recipient.educationLevel == ed.value ? (
                    <Text style={{ color: "#00487d" }}>[X] </Text>
                  ) : (
                    "[  ] "
                  )}
                  {ed.label}
                </Text>
              ))}
            </View>

            <View style={{ width: "30%", padding: 3 }}>
              <Text
                style={{
                  fontSize: 9,
                  marginBottom: 3,
                }}
              >
                Activité professionnelle actuelle
              </Text>

              {workContracts.map((ed, edk) => (
                <Text key={"wc" + edk} style={{ ...styles.textSmall }}>
                  {datas.recipient.workContract == ed.value ? (
                    <Text style={{ color: "#00487d" }}>[X] </Text>
                  ) : (
                    "[  ] "
                  )}
                  {ed.label}
                </Text>
              ))}
              <Text style={{ paddingTop: 3, fontSize: 8 }}>
                Nature du contrat
              </Text>
              {workSituations.map((ed, edk) => (
                <Text key={"wk" + edk} style={{ ...styles.textSmall }}>
                  {datas.recipient.workSituation == ed.value ? (
                    <Text style={{ color: "#00487d" }}>[X] </Text>
                  ) : (
                    "[  ] "
                  )}
                  {ed.label}
                </Text>
              ))}
            </View>
            <View style={{ width: "23%", padding: 3 }}>
              <Text
                style={{
                  fontSize: 9,
                  marginBottom: 3,
                }}
              >
                Permis de conduire
              </Text>
              {drivePermits.map((ed, edk) => (
                <Text key={"dp" + edk} style={{ ...styles.textSmall }}>
                  {datas.recipient.driveLicenses?.includes(ed.value) ? (
                    <Text style={{ color: "#00487d" }}>[X] </Text>
                  ) : (
                    "[  ] "
                  )}
                  {ed.label}
                </Text>
              ))}
            </View>
            <View style={{ width: "22%", padding: 3 }}>
              <Text
                style={{
                  fontSize: 9,
                  marginBottom: 3,
                }}
              >
                Moyens de transport
              </Text>
              {transports.map((ed, edk) => (
                <Text key={"tp" + edk} style={{ ...styles.textSmall }}>
                  {datas.recipient.transports?.includes(ed.value) ? (
                    <Text style={{ color: "#00487d" }}>[X] </Text>
                  ) : (
                    "[  ] "
                  )}
                  {ed.label}
                </Text>
              ))}
            </View>
          </View>
        </View>
        <View style={{ padding: 5, marginTop: 5 }}>
          <View>
            <Text style={{ ...styles.subtitle }}>Enfants à charge :</Text>
            <Text style={{ ...styles.text }}>
              Nombre d'enfants à charge dans le foyer :{" "}
              <Text style={{ color: "#00487d" }}>
                {datas.recipient.nbChildrenOrDependant}
              </Text>
            </Text>
          </View>
        </View>
        <View style={{ padding: 5, marginTop: 5 }}>
          <View>
            <Text style={{ ...styles.subtitle }}>
              La situation sociale actuelle du bénéficiaire :{" "}
            </Text>

            <Text
              style={{
                fontSize: 11,
                marginBottom: 5,
              }}
            >
              Si la personne rencontre des difficultés relatives à l'accès à une
              activité professionnelle, indiquer à quoi sont liées ces
              difficultés :
            </Text>
            {accompanies.map((ed, edk) => (
              <Text
                key={"ac" + edk}
                style={{ ...styles.textSmall, fontSize: 9 }}
              >
                {datas.recipient.accompany?.includes(ed.value) ? (
                  <Text style={{ color: "#00487d" }}>[X] </Text>
                ) : (
                  "[  ] "
                )}
                {ed.label}
              </Text>
            ))}
          </View>
        </View>
        <View
          style={{
            fontSize: 9,
            position: "absolute",
            bottom: 10,
            right: 10,
          }}
        >
          <Text>Page 1/3</Text>
        </View>
      </Page>
      <Page wrap size="A4" orientation="portrait" style={{ padding: "20px" }}>
        <View style={{ padding: 5, marginTop: 5 }}>
          <Text style={{ ...styles.subtitle }}>Mon projet :</Text>
          <Text style={{ fontSize: 11 }}>
            Description du projet et observations :
          </Text>
          <Text
            style={{
              height: 100,
              borderBottom: 1,
              borderTop: 1,
              fontSize: 12,
              borderColor: "lightgrey",
            }}
          >
            {datas.recipient.professionalProject}
          </Text>
        </View>
        <View style={{ padding: 5, marginTop: 5 }}>
          <View style={{ marginBottom: 20 }}>
            <Text style={{ ...styles.subtitle }}>
              Mon orientation {">"}{" "}
              <Text
                style={{
                  fontSize: 16,
                  color: "#00487d",
                  textAlign: "center",
                  padding: 5,
                  paddingBottom: 15,
                  fontWeight: "700",
                  textDecoration: "underline",
                }}
              >
                {datas.recipient.orientation}
              </Text>
            </Text>
          </View>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View
              style={{
                ...styles.border,
                padding: 5,
                width: "38%",
                marginRight: "2%",
              }}
            >
              <Text style={{ ...styles.text }}>Nom du référent unique : </Text>
              <Text
                style={{
                  ...styles.text,
                  color: "#00487d",
                  marginBottom: 5,
                  height: 25,
                }}
              >
                {datas.recipient.rdv?.name}
              </Text>
              <Text style={{ ...styles.text }}>Téléphone : </Text>
              <Text
                style={{
                  ...styles.text,
                  color: "#00487d",
                  marginBottom: 5,
                  height: 25,
                }}
              >
                {datas.recipient.rdv?.phone}
              </Text>
              <Text style={{ ...styles.text }}>Date de mon rendez-vous : </Text>
              <Text
                style={{
                  ...styles.text,
                  color: "#00487d",
                  marginBottom: 5,
                  height: 25,
                }}
              >
                {datas.recipient.rdv?.date &&
                  DateTime.fromISO(datas.recipient.rdv.date).toFormat(
                    "DDDD à HH'h'mm"
                  )}
              </Text>
              <Text style={{ ...styles.text }}>Lieu du rendez-vous : </Text>
              <Text
                style={{
                  ...styles.text,
                  color: "#00487d",
                  marginBottom: 5,
                  height: 25,
                }}
              >
                {datas.recipient.rdv?.location}
              </Text>
            </View>
            <View
              style={{
                width: "60%",
              }}
            >
              <Text style={{ fontSize: 10 }}>
                Ce PASS orientation prend effet à partir du :{" "}
                <Text style={{ color: "#00487d" }}>
                  {datas.recipient.orientationDate &&
                    DateTime.fromISO(datas.recipient.orientationDate).toFormat(
                      "dd/MM/yyyy"
                    )}
                </Text>
              </Text>

              <View
                style={{
                  ...styles.text,
                  marginTop: 5,
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    marginBottom: 3,
                    borderLeft: "3pt solid black",
                    paddingLeft: 8,
                  }}
                >
                  Je suis informé(e)
                </Text>
                <Text>
                  que si je ne donne pas suite au rendez-vous fixé avec mon
                  référent de parcours, je m'expose{" "}
                  <Text style={{ textDecoration: "underline" }}>
                    à une suspension de mon RSA sans autre préavis.
                  </Text>
                </Text>
              </View>

              <View
                style={{
                  ...styles.text,
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    marginBottom: 3,
                    borderLeft: "3pt solid black",
                    paddingLeft: 8,
                  }}
                >
                  Mes engagements :{" "}
                </Text>
                <Text
                  style={{
                    marginBottom: 3,
                  }}
                >
                  Je m'engage à effectuer toutes les démarches et activités
                  nécessaires à mon parcours d'accompagnement.
                </Text>
                <Text>
                  Si je suis orienté(e) vers France Travail, je m'engage à :
                </Text>
                <Text>
                  - M'inscrire à France Travail car je suis disponible pour
                  occuper un emploi
                </Text>
                <Text
                  style={{
                    marginBottom: 3,
                  }}
                >
                  - Maintenir mon inscription et poursuivre mes démarches de
                  recherche d'emploi
                </Text>
                <Text>
                  Si je suis orienté(e) vers Mission Locale, je m'engage à :
                </Text>
                <Text>
                  - M'inscrire à Mission Locale car je suis disponible pour un
                  accompagnement social et professionnel.
                </Text>
                <Text>
                  - Effectuer toutes les démarches et activités nécessaires à
                  mon parcours d'accompagnement.
                </Text>
              </View>

              <View
                style={{
                  ...styles.text,
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 20,
                }}
              >
                <View
                  style={{
                    ...styles.border,
                    width: "50%",
                    padding: 4,
                    paddingBottom: 30,
                  }}
                >
                  <Text style={{ ...styles.text, marginBottom: 3 }}>
                    Le bénéficiaire
                  </Text>
                  <Text style={{ ...styles.text }}>
                    Date : le ......../......../........
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.border,
                    width: "50%",
                    padding: 4,
                    paddingBottom: 30,
                  }}
                >
                  <Text style={{ ...styles.text, marginBottom: 3 }}>
                    Pour le directeur de la CAF
                  </Text>
                  <Text style={{ ...styles.text }}>
                    Date : le ......../......../........
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={{ padding: 5, marginTop: 5 }}>
          <Text style={{ fontSize: 14, marginBottom: 5 }}>
            L'opérateur adapté au besoin d'accompagnement prioritaire identifié
            lors de cet entretien
          </Text>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View
              style={{
                ...styles.border,
                padding: 5,
                width: "38%",
                marginRight: "2%",
              }}
            >
              <Text style={{ ...styles.text }}>Organisme/association : </Text>
              <Text
                style={{
                  ...styles.text,
                  borderBottom: 1,
                  borderBottomStyle: "dotted",
                  padding: 10,
                  borderBottomColor: "lightgrey",
                  marginBottom: 5,
                }}
              ></Text>
              <Text style={{ ...styles.text }}>
                Personne à contacter avant la date du RDV CAF :{" "}
              </Text>
              <Text
                style={{
                  ...styles.text,
                  borderBottom: 1,
                  borderBottomStyle: "dotted",
                  padding: 10,
                  borderBottomColor: "lightgrey",
                  marginBottom: 5,
                }}
              ></Text>
              <Text style={{ ...styles.text }}>Téléphone : </Text>
              <Text
                style={{
                  ...styles.text,
                  borderBottom: 1,
                  borderBottomStyle: "dotted",
                  padding: 10,
                  borderBottomColor: "lightgrey",
                  marginBottom: 5,
                }}
              ></Text>
            </View>
            <View
              style={{
                width: "60%",
              }}
            >
              <View
                style={{
                  ...styles.text,
                  marginTop: 5,
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    marginBottom: 3,
                    borderLeft: "3pt solid black",
                    paddingLeft: 8,
                  }}
                >
                  Je suis informé(e)
                </Text>
                <Text>
                  que la non-signature de ce contrat et son non-respect m'expose{" "}
                  <Text style={{ textDecoration: "underline" }}>
                    à une suspension de mon RSA sans autre préavis.
                  </Text>
                </Text>
              </View>

              <View
                style={{
                  ...styles.text,
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 20,
                }}
              >
                <View
                  style={{
                    ...styles.border,
                    width: "50%",
                    padding: 4,
                    paddingBottom: 60,
                  }}
                >
                  <Text style={{ ...styles.text, marginBottom: 3 }}>
                    Le bénéficiaire
                  </Text>
                  <Text style={{ ...styles.text }}>
                    Date : le ......../......../........
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.border,
                    width: "50%",
                    padding: 4,
                    paddingBottom: 60,
                  }}
                >
                  <Text style={{ ...styles.text, marginBottom: 3 }}>
                    Pour le Directeur de la CAF
                  </Text>
                  <Text style={{ ...styles.text }}>
                    Date : le ......../......../........
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={{ padding: 5, marginTop: 5 }}>
          <Text style={{ ...styles.text }}>
            Je suis inscrit(e) à la réunion d'information du
            .................................. à ..........h.......... Lieu :
            ...................................................
          </Text>

          <View style={{ ...styles.text, marginTop: 10 }}>
            <Text style={{ fontSize: 14, marginBottom: 5 }}>
              Mes engagements :
            </Text>
            <Text>
              En cas de difficulté à accomplir les démarches prévues dans le
              présent PASS Orientation je dois contacter la CAF sans délai aux
              coordonnées suivantes :
            </Text>
            <Text
              style={{ padding: 10, borderBottom: "1pt dotted lightgrey" }}
            ></Text>
            <Text
              style={{ padding: 10, borderBottom: "1pt dotted lightgrey" }}
            ></Text>
          </View>
        </View>
        <View
          style={{
            fontSize: 9,
            position: "absolute",
            bottom: 10,
            right: 10,
          }}
        >
          <Text>Page 2/3</Text>
        </View>
      </Page>
      <Page wrap size="A4" orientation="portrait" style={{ padding: "20px" }}>
        <View style={{ padding: 5, marginTop: 5 }}>
          <Text style={{ ...styles.subtitle }}>Coordonnées des opérateurs</Text>

          {datas.operators?.map((operator) => {
            return (
              <View style={{ marginTop: 20 }}>
                <Text style={{ fontSize: 16 }}>{operator.name}</Text>
                {operator.address ? (
                  <Text
                    style={{ fontSize: 12, paddingLeft: 10, paddingTop: 5 }}
                  >
                    Adresse :{" "}
                    <Text style={{ color: "#00487d" }}>
                      {operator.address} {operator.location?.postalCode}{" "}
                      {operator.location?.name}
                    </Text>
                  </Text>
                ) : null}
                {operator.phone ? (
                  <Text
                    style={{ fontSize: 12, paddingLeft: 10, paddingTop: 5 }}
                  >
                    Téléphone :{" "}
                    <Text style={{ color: "#00487d" }}>
                      {tools.formatPhone(operator.phone)}
                    </Text>
                  </Text>
                ) : null}
                {operator.email ? (
                  <Text
                    style={{ fontSize: 12, paddingLeft: 10, paddingTop: 5 }}
                  >
                    Email :{" "}
                    <Text style={{ color: "#00487d" }}>{operator.email}</Text>
                  </Text>
                ) : null}
                {operator.link ? (
                  <Text
                    style={{ fontSize: 12, paddingLeft: 10, paddingTop: 5 }}
                  >
                    Site internet :{" "}
                    <Text style={{ color: "#00487d" }}>{operator.link}</Text>
                  </Text>
                ) : null}
              </View>
            );
          })}
        </View>
        <View
          style={{
            fontSize: 9,
            position: "absolute",
            bottom: 10,
            right: 10,
          }}
        >
          <Text>Page 3/3</Text>
        </View>
      </Page>
    </Document>
  );
};

const PassOrientation = (props) => {
  const [isInit, setIsInit] = useState(false);
  const [datas, setDatas] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [docReady, setDocReady] = useState(false);
  const [displayPreview, setDisplayPreview] = useState(false);
  const [pdfKey, setPdfKey] = useState(1);

  useEffect(() => {
    setIsLoading(true);
    dataService.get(
      "recipients/me/passorientation",
      (datas) => {
        setDatas(datas);

        setIsInit(true);
        // setTimeout(() => {
        //   document.querySelector(".dl-po-btn").click();
        // }, 1500);
      },
      setErrors,
      () => setIsLoading(false)
    );
  }, []);

  return (
    <div key={pdfKey}>
      <img src="/assets/logo-po.jpg" width="1" />

      {isLoading ? (
        <Loader />
      ) : errors.other ? (
        <div className="alert alert-danger">{errors.other}</div>
      ) : !datas ? null : (
        <>
          <div
            className="text-center pt-3  pb-3 px-3  d-flex justify-content-center align-items-center flex-column"
            style={{ gap: "1rem" }}
          >
            <PDFDownloadLink
              className="btn btn-primary dl-po-btn mb-2"
              document={<MyDoc datas={datas} />}
              fileName="PassOrientation.pdf"
            >
              {({ blob, url, loading, error }) => {
                var text = loading ? (
                  "Chargement..."
                ) : (
                  <>
                    <i className="fa fa-download mr-2" />
                    Télécharger le PASS ORIENTATION
                  </>
                );
                if (error) {
                  console.log("gen err", error);
                  setPdfKey(pdfKey + 1);
                }
                if (!loading) {
                  setDocReady(true);
                }
                return <>{text}</>;
              }}
            </PDFDownloadLink>
            {docReady && (
              <button
                className="btn btn-info mb-2"
                onClick={() => setDisplayPreview(!displayPreview)}
              >
                <i
                  className={`fa mr-2 ${
                    displayPreview ? "fa-eye-slash" : "fa-eye"
                  }`}
                />
                {displayPreview ? "Fermer" : "Voir dans"} la visionneuse
              </button>
            )}
            {docReady && displayPreview && (
              <div
                style={{
                  width: "100%",
                  height: 600,
                }}
              >
                <PDFViewer style={{ width: "100%", height: "100%", border: 0 }}>
                  <MyDoc datas={datas} />
                </PDFViewer>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PassOrientation;
