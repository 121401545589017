import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { api_url } from "../../../config";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import tools from "../../../helpers/tools";

var strTimeout = null;

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [state, dispatch] = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setError] = useState(false);
  const [search, setSearch] = useState({
    user: { role: "ADMIN" },
    page: 1
  });
  const [nbPages, setNbPages] = useState(1);

  useEffect(() => {
    var lsState = tools.getState("UserList", { search: search });
    setSearch(lsState.search);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    clearTimeout(strTimeout);
    strTimeout = setTimeout(() => {
      searchUsers();
    }, 1500);
    tools.saveState("UserList", "search", search);
  }, [search]);

  function searchUsers() {
    setError(false);
    Axios.post(api_url + "users/search", search)
      .then(res => {
        setUsers(res.data.users);
        setNbPages(Math.ceil(parseInt(res.data.count) / 25));
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        setError(err.response.data);
        setUsers([]);
      });
  }
  function updateUserSearch(e) {
    var value = e.target.value;
    var su = { ...search };
    if (value == "") {
      delete su.user[e.target.name];
    } else {
      su.user[e.target.name] = value;
    }

    setSearch(su);
  }

  function changePage(i) {
    setSearch(search => {
      return { ...search, page: i };
    });
  }

  return (
    <Page
      container="container"
      action={{ to: "/users/create", text: "Créer un utilisateur" }}
      title={"Liste des utilisateurs"}
    >
      <div className="row mb-4">
        {state.constants.items.length == 0 ? (
          <Loader />
        ) : (
          <div className="col-5">
            <div className="form-group">
              <label>Rôle</label>
              <ul className="nav nav-tabs mb-3" style={{marginTop:18}}>
                {state.constants.items &&
                  state.constants.items.ROLES.map((r, rk) => {
                    return (
                      <li className="nav nav-tabs mb-3" key={"nvlk" + rk}>
                        <a
                          className={
                            "nav-link " +
                            (search.user.role == r.id ? "active" : "default")
                          }
                          onClick={e =>
                            updateUserSearch({
                              target: { name: "role", value: r.id }
                            })
                          }
                          data-toggle="tab"
                          href="#FRtab"
                          role="tab"
                        >
                          {r.name}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        )}
        <div className="col">
          <Control
            label="Recherche"
            name="searchString"
            value={search.user.searchString}
            change={updateUserSearch}
            suffix={
              <i
                className="fa fa-search ml-2 pt-2"
                style={{ lineHeight: "40px" }}
              ></i>
            }
          />
        </div>
        <div className="col align-items-center justify-content-end d-flex">
          {users.length} utilisateurs trouvés
        </div>
      </div>
      <div className="row">
        <div className="col-12 p-4">
          {errors && errors.other ? (
            <div className="text-danger m-auto text-center">{errors.other}</div>
          ) : isLoading ? (
            <Loader />
          ) : (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th>Identifiant</th>
                    <th>Prénom</th>
                    <th>Nom</th>
                    <th>Code postal</th>
                    <th>Ville</th>
                    <th>Modifier</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((u, k) => (
                    <tr
                      key={u.id}
                      className="animated fadeInUp"
                      style={{
                        animationDelay: 100 * k + "ms"
                      }}
                    >
                      <td>{u.username}</td>
                      <td>{u.firstname}</td>
                      <td>{u.lastname}</td>
                      <td>{u.location ? u.location.postalCode : "?"}</td>
                      <td>
                        {u.location
                          ? u.location.locationName
                            ? u.location.name + " - " + u.location.locationName
                            : u.location.name
                          : "?"}
                      </td>
                      <td>
                        <Link to={"/users/" + u.id}>
                          <i className="fa fa-edit"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={changePage}
          />
        </div>
      </div>
    </Page>
  );
};

export default UserList;
