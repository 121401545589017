import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import Axios from "axios";
import { api_url } from "../../../config";
import PostalCode from "../../common/PostalCode";

const UserDetail = (props) => {
  const [state, dispatch] = useStore();

  const [user, setUser] = useState({
    username: "",
    email: "",
    password: "",
    role: "",
    isSuper: false,
    emailValidation: {
      token: "",
      tokenSendedAt: false,
      isValidated: false,
    },
    isCGUAccepted: false,
    isNewsletter: false,
    gender: 1,
    firstname: "",
    lastname: "",
    birthdate: new Date(),
    address: "",
    additionalAddress: "",
    postalCode: "",
    city: "",
    country: "",
    phone: "",
    iban: "",
    rank: 0,
    balance: 0,
  });
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.match.params.id != "create") {
      Axios.get(api_url + "users/" + props.match.params.id)
        .then((res) => {
          setUser(res.data);
        })
        .catch((err) => {
          setErrors(err.response.data);
        });
    }
  }, []);

  function updateData(e) {
    var value = e.target.value;
    if (e.target.type == "checkbox") {
      if (e.target.checked) {
        value = true;
      } else {
        value = false;
      }
    }
    setUser({ ...user, [e.target.name]: value });
  }

  function save() {
    setIsLoading(true);
    setErrors({});
    if (props.match.params.id == "create") {
      //create
      Axios.post(api_url + "users/private", user)
        .then((res) => {
          setUser(res.data);
          setIsLoading(false);
          window.location.href = "/users/" + res.data.id;
        })
        .catch((err) => {
          setErrors(err.response.data);
          setIsLoading(false);
        });
    } else {
      //update
      Axios.patch(api_url + "users/" + props.match.params.id, user)
        .then((res) => {
          setUser(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setErrors(err.response.data);
          setIsLoading(false);
        });
    }
  }

  return state.constants.items.length == 0 || isLoading ? (
    <Loader />
  ) : (
    <Page
      container="container"
      title="Détail utilisateur"
      errors={errors}
      back={"/users"}
    >
      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <a
            className="nav-link active"
            id="profile-tab"
            data-toggle="tab"
            href="#profile"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            Profil
          </a>
        </li>
        {user.role != "ADMIN" && (
          <>
            <li className="nav-item">
              <a
                className="nav-link"
                id="qualification-tab"
                data-toggle="tab"
                href="#qualification"
                role="tab"
                aria-controls="home"
                aria-selected="false"
              >
                Qualification
              </a>
            </li>
          </>
        )}
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <Control
            label="Rôle"
            type="btnList"
            name="role"
            value={user.role}
            datas={state.constants.items.ROLES}
            dataIndex="id"
            dataLabel="name"
            change={updateData}
          />
          {user.role == "ADMIN" && (
            <Control
              label="Est super admin ?"
              name="isSuper"
              type="checkbox"
              checked={user.isSuper}
              change={updateData}
            />
          )}

          <div className="row">
            <div className="col-4">
              {" "}
              <Control
                label="Civilité"
                type="select"
                name="gender"
                value={user.gender}
                datas={state.constants.items.GENDERS}
                dataIndex="id"
                dataLabel="name"
                change={updateData}
              />
            </div>
            <div className="col-4">
              <Control
                label="Prénom"
                name="firstname"
                value={user.firstname}
                change={updateData}
              />
            </div>
            <div className="col-4">
              {" "}
              <Control
                label="Nom"
                name="lastname"
                value={user.lastname}
                change={updateData}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <Control
                label="Identifiant"
                name="username"
                value={user.username}
                change={updateData}
              />
            </div>
            <div className="col-4">
              <Control
                label="Email"
                name="email"
                value={user.email}
                change={updateData}
              />
            </div>
            <div className="col-4">
              <Control
                label="Mot de passe"
                name="password"
                type="password"
                value={user.password}
                change={updateData}
              />
            </div>
          </div>
          {user.role != "ADMIN" && (
            <>
              <div className="row">
                <div className="col-4">
                  <Control
                    label="Adresse"
                    name="address"
                    value={user.address}
                    change={updateData}
                  />
                </div>
                <div className="col-4">
                  <Control
                    label="Adresse complément"
                    name="additionalAddress"
                    value={user.additionalAddress}
                    change={updateData}
                  />
                </div>
                <div className="col-4">
                  <PostalCode
                    value={user.postalCode}
                    updateData={updateData}
                    location={user.location}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  {" "}
                  <Control
                    label="Date de naissance"
                    type="date"
                    name="birthdate"
                    value={DateTime.fromISO(user.birthdate).toFormat(
                      "yyyy-MM-dd"
                    )}
                    change={updateData}
                  />
                </div>
                <div className="col-4">
                  <Control
                    label="Téléphone"
                    name="phone"
                    value={user.phone}
                    change={updateData}
                  />
                </div>
              </div>

              <Control
                label="Souscription à la newsletter ?"
                name="isNewsletter"
                type="checkbox"
                checked={user.isNewsletter}
                change={updateData}
              />
            </>
          )}
        </div>
        <div
          className="tab-pane fade"
          id="qualification"
          role="tabpanel"
          aria-labelledby="qualification-tab"
        >
          ...
        </div>
      </div>

      <button className="btn btn-success btn-save" onClick={save}>
        Enregistrer
      </button>
    </Page>
  );
};

export default UserDetail;
