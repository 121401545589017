import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      className="custom-footer bg-light py-4  w-100 "
      style={{
        bottom: 0,
        left: 0,
        height: 200,
        marginTop: 280,
        borderTop: "1px solid #e6e6e6",
      }}
    >
      <div className="d-center">
        <a
          target="_BLANK"
          href="/docs/informatique-et-libertes.html"
          className="btn btn-link"
        >
          Informatique et libertés
        </a>

        <a
          target="_BLANK"
          href="/docs/protection-des-donnees-personnelles.html"
          className="btn btn-link"
        >
          Protection des données personnelles
        </a>
          <a
              target="_BLANK"
              href="/docs/mentions-legales.html"
              className="btn btn-link"
          >
              Mentions légales
          </a>
      </div>
    </footer>
  );
};

export default Footer;
