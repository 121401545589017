import React, { useEffect, useState } from "react";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import Control from "../../common/Control";
import GroupedDigitControl from "../../common/GroupedDigitControl";
import SaveBtn from "../../common/SaveBtn";

const ConfirmEpPresence = (props) => {
  const [state, dispatch] = useStore();
  const { EP_SANCTION_PRESENCES } = state.constants.items;
  const [presence, setPresence] = useState("");
  const [nir, setNir] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const token = props.match.params.token;
  const epId = props.match.params.epid;
  const [presenceVerif, setPresenceVerif] = useState(null);

  const savePresence = () => {
    dataService.patch(
      `epdetails/${epId}/confirm-presence`,
      { token, nir: nir, answer: presence },
      setPresenceVerif,
      setErrors,
      () => setIsLoading(false)
    );
  };

  console.log("pre", presence);

  return !token ? (
    <div className="d-center mt-3">
      <div className="alert alert-danger">
        Ce lien n'est plus valide ou vous avez déjà répondu.
      </div>
    </div>
  ) : presenceVerif ? (
    <div className="d-center mt-3">
      <div className="alert alert-success">
        Merci, votre réponse à bien été enregistrée.
      </div>
    </div>
  ) : (
    <div className="d-center flex-column mt-3">
      <div className="alert alert-info">
        <i className="fa fa-exclamation-circle mr-2" />
        Veuillez confirmer votre modalité de réponse dès la réception de votre
        courrier de convocation en renseignant les informations ci-dessous :
      </div>

      <div>
        <label>
          Entrez votre numéro de sécurité sociale qui figure sur votre carte
          vitale
        </label>
        <GroupedDigitControl
          name={"nir"}
          value={nir}
          separation={[1, 2, 2, 2, 3, 3, 2]}
          separator={"   "}
          change={(e) => {
            if (e.target.value == "269054958815780") {
              alert(
                "Vous ne devez pas rentrer le numéro affiché sur l'image mais celui de votre propre carte vitale."
              );
            } else {
              setNir(e.target.value);
            }
          }}
          autoFocus={true}
        />
        <Control
          label="Indiquez si vous serez présent en cliquant sur l'un des boutons ci-dessous"
          type="btnList"
          value={presence}
          change={(e) => setPresence(e.target.value)}
          error={errors}
          datas={EP_SANCTION_PRESENCES}
        />

        <div
          className={`d-center flex-column animated faster ${
            presence ? "fadeInUp" : "d-none"
          }`}
        >
          <div className="alert alert-info">
            <i className="fa fa-exclamation-circle mr-2" />
            Veuillez compléter le verso de votre convocation et nous l'adresser
            dans les délais impartis.
          </div>
          {errors.other && (
            <div className="alert alert-danger">{errors.other}</div>
          )}
          <SaveBtn
            save={savePresence}
            isSaving={isLoading}
            text="Envoyer ma réponse"
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmEpPresence;
