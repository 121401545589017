import { atom, useAtom } from "jotai";
import { toast } from "react-toastify";
import dataService from "../../helpers/dataService";
import {
  cerErrorsAtom,
  CERModel,
  currentCERViewAtom,
  currentRecipientAtom,
  isLoadingCERAtom,
  isLoadingCERGoalAtom,
  isRecipientCERDraftAtom,
  recipientCERAtom,
} from "./atoms/CERModuleAtoms";

const noCERAtom = atom(false);

const useCERModule = () => {
  const [currentRecipient, setCurrentRecipient] = useAtom(currentRecipientAtom);
  const [recipientCER, setRecipientCER] = useAtom(recipientCERAtom);
  const [currentCERView, setCurrentCERView] = useAtom(currentCERViewAtom);
  const [isRecipientCERDraft] = useAtom(isRecipientCERDraftAtom);
  const [isLoadingCER, setIsLoadingCER] = useAtom(isLoadingCERAtom);
  const [isLoadingCERGoal, setIsLoadingCERGoal] = useAtom(isLoadingCERGoalAtom);
  const [cerErrors, setCerErrors] = useAtom(cerErrorsAtom);
  const [noCER, setNoCER] = useAtom(noCERAtom);

  const resetAtoms = () => {
    setCurrentRecipient(null);
    setRecipientCER(JSON.parse(JSON.stringify(CERModel)));
    setCurrentCERView("CER_LIST");
    setIsLoadingCER(false);
    setCerErrors({});
  };

  const updateRecipientCER = (newRecipientCER) => {
    setRecipientCER(newRecipientCER);
  };

  const getRecipientCER = () => {
    setIsLoadingCER(true);
    dataService.get(
      `recipients/me/cer`,
      (data) => {
        if (Object.keys(data).length == 0) {
          setNoCER(true);
        }
        setRecipientCER({
          ...CERModel,
          ...data,
        });
      },
      setCerErrors,
      () => setIsLoadingCER(false)
    );
  };

  const createRecipientCER = () => {
    setIsLoadingCER(true);
    dataService.post(
      `cers`,
      {
        ...recipientCER,
        userRecipient: currentRecipient.id,
      },
      (data) => {
        setRecipientCER(data);
        toast.success("Le CER a bien été créé");
      },
      setCerErrors,
      () => {
        setIsLoadingCER(false);
      }
    );
  };
  const patchRecipientCER = () => {
    if (validateCerGoals().hasErrors) {
      return false;
    }
    setIsLoadingCER(true);

    dataService.patch(
      `cers/${recipientCER.id}`,
      recipientCER,
      (data) => {
        setRecipientCER(data);
        toast.success("Le CER a bien été modifié");
      },
      setCerErrors,
      () => {
        setIsLoadingCER(false);
      }
    );
  };

  const patchRecipientCERSigned = (signedAt) => {
    setIsLoadingCER(true);
    dataService.patch(
      `cers/${recipientCER.id}/sign`,
      {
        signedAt,
      },
      (data) => {
        setRecipientCER({ ...recipientCER, status: data.status, signedAt: data.signedAt });
      },
      setCerErrors,
      () => {
        setIsLoadingCER(false);
      }
    );
  };
  const patchRecipientCERGoals = (newRecipientCER, goalIndex) => {
    setIsLoadingCERGoal(true);
    dataService.patch(
      `cers/${recipientCER.id}/goals`,
      newRecipientCER,
      (data) => {
        setRecipientCER(newRecipientCER);
      },
      setCerErrors,
      () => {
        setIsLoadingCERGoal(false);
      }
    );
  };

  const validateCerGoals = () => {
    var hasErrors = false;
    if (recipientCER.goals.length)
      recipientCER.goals.forEach((goal) => {
        if (goal.steps.length == 0) {
          hasErrors = true;
          toast.error("Un objectif doit posséder au moins une démarche");
        }
      });

    return { hasErrors };
  };

  const onAddObjective = (goal) => {
    setRecipientCER({
      ...recipientCER,
      goals: [...recipientCER.goals, goal],
    });
  };

  const onUpdateObjectives = (newObjectives) => {
    setRecipientCER({
      ...recipientCER,
      goals: newObjectives,
    });
  };
  const onUpdateObjective = (goal, index) => {
    setRecipientCER({
      ...recipientCER,
      goals: recipientCER.goals.map((g, gk) => {
        return index == gk ? goal : g;
      }),
    });
  };
  const onUpdateObjectiveAndSave = (goal, index) => {
    const newRecipientCER = {
      ...recipientCER,
      goals: recipientCER.goals.map((g, gk) => {
        return index == gk ? goal : g;
      }),
    };
    patchRecipientCERGoals(newRecipientCER, index);
  };
  const onDeleteObjective = (goal, index) => {
    setRecipientCER({
      ...recipientCER,
      goals: recipientCER.goals.filter((g, gk) => gk != index),
    });
  };

  const checkAllStepsDone = (goal) => {
    let allDone = true;
    goal.steps.forEach((step) => {
      if (step.detail.status != "DONE") allDone = false;
    });
    return allDone;
  };

  const checkStepInProgress = (goal) => {
    let inProgress = false;
    goal.steps.forEach((step) => {
      if (step.detail.status == "IN_PROGRESS") inProgress = true;
    });
    return inProgress;
  };

  return {
    isLoadingCER,
    isLoadingCERGoal,
    currentRecipient,
    setCurrentRecipient,
    recipientCER,
    isRecipientCERDraft,
    setRecipientCER,
    currentCERView,
    setCurrentCERView,
    resetAtoms,
    cerErrors,
    noCER,
    actions: {
      getRecipientCER,
      createRecipientCER,
      updateRecipientCER,
      patchRecipientCER,
      patchRecipientCERSigned,
      checkAllStepsDone,
      checkStepInProgress,
    },
    events: {
      onAddObjective,
      onUpdateObjective,
      onUpdateObjectiveAndSave,
      onDeleteObjective,
      onUpdateObjectives,
    },
  };
};

export default useCERModule;
