import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import Axios from "axios";
import { api_url } from "../../../config";
import Loader from "../../common/Loader";
import DigitControl from "../../common/DigitControl";
import GroupedDigitControl from "../../common/GroupedDigitControl";
import { Link } from "react-router-dom";

const ForgotPassword = ({
  setForgotPassword = () => {},
  nir = "",
  inModal = false,
}) => {
  const [login, setLogin] = useState(nir);
  const [emailOk, setEmailOk] = useState(false);
  const [errors, setErrors] = useState({});
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    if (nir != "") {
      setLogin(nir);
    }
  }, [nir]);

  function checkEmail() {
    setCheckingEmail(true);
    Axios.post(api_url + "auth/renew-password/app", { login: login })
      .then((res) => {
        setCheckingEmail(false);
        setEmailSent(true);
      })
      .catch((err) => {
        setCheckingEmail(false);

        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      });
  }

  return (
    <div
      className={
        !inModal
          ? "card m-auto col-md-6 col-lg-6 col-12 px-0 animated fadeInRight faster"
          : "card m-auto col-12 shadow-none"
      }
    >
      {emailSent ? (
        <div className="card-body p-4 p-lg-5 p-md-4 text-center overflow-hidden">
          <p className="text-success mb-0">
            Si votre email est enregistré dans notre système, un email vous sera
            envoyé pour réinitialiser votre mot de passe. Consultez votre boite
            email et cilquez sur le lien qu'il contient.
          </p>
        </div>
      ) : (
        <>
          {!inModal && (
            <div className="card-header">
              <h1 className="mb-0">Mot de passe oublié</h1>
            </div>
          )}
          <div className="card-body p-4 p-lg-5 p-md-4">
            <p className="mb-0">
              Une fois ce formulaire rempli, vous recevrez un email contenant un
              lien qui vous permettra de définir un nouveau mot de passe pour
              votre compte.
            </p>
            <label className="mt-5">Numéro de sécurité sociale</label>

            <GroupedDigitControl
              name="nir"
              value={nir}
              separation={[1, 2, 2, 2, 3, 3, 2]}
              change={(e) => setLogin(e.target.value)}
              separator={"   "}
            />

            <span className="text-danger d-block mt-2 text-center">
              {errors.login}
            </span>
            {checkingEmail ? (
              <Loader />
            ) : (
              <>
                <button
                  className="btn btn-success w-100 mt-4"
                  onClick={checkEmail}
                >
                  Envoyer le lien par email
                </button>
                {!inModal && (
                  <button
                    onClick={() => setForgotPassword(false)}
                    className="btn btn-link w-100 mt-3"
                  >
                    <i className="fa fa-chevron-left mr-2"></i>Revenir à la
                    connexion
                  </button>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
