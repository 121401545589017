import React, { useEffect, useState } from "react";
import Axios from "axios";
import { api_url } from "../../../config";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import useStore from "../../../context/useStore";
import ModalBtn from "../../common/ModalBtn";
import Modal from "../../common/Modal";
import Control from "../../common/Control";
import Colors from "../../../color-theme.scss";
import { toast } from "react-toastify";
import SaveBtn from "../../common/SaveBtn";
import ChooseSiteReport from "./ChooseSiteReport";

const initialDays = [
  { id: 1, name: "Lundi" },
  { id: 2, name: "Mardi" },
  { id: 3, name: "Mercredi" },
  { id: 4, name: "Jeudi" },
  { id: 5, name: "Vendredi" },
];

const RecipientRdv = ({
  rdvs,
  errors,
  setErrors,
  recipientId,
  getRdvs,
  getDatas,
  isLoading,
  rdspId,
}) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const [rdvSlots, setRdvSlots] = useState(null);
  const [isLoadingSlots, setIsLoadingSlots] = useState(false);
  const [rdvSelected, setRdvSelected] = useState(null);
  const [currentRdv, setCurrentRdv] = useState(null);
  const [originSelected, setOriginSelected] = useState("1");
  const [reasonSelected, setReasonSelected] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [daysSelected, setDaysSelected] = useState([]);
  const [days, setDays] = useState(initialDays);

  useEffect(() => {
    if (rdvs && rdvs.length > 0) {
      reCalcDays();
    }
  }, [rdvSlots]);
  function reCalcDays() {
    if (!rdvSlots || rdvSlots.length == 0) return false;
    var dys = JSON.parse(JSON.stringify(days));

    dys = dys.filter((d) => {
      if (
        rdvSlots.find((r) => {
          var daystart = DateTime.fromISO(r.start).weekday;
          if (daystart == d.id) {
            return true;
          }
        })
      ) {
        return d;
      }
    });
    setDays(dys);
  }
  function updateTimeSelected(e) {
    var { name, value } = e.target;
    var ev = JSON.parse(JSON.stringify(daysSelected));

    if (ev.includes(value)) {
      ev = ev.filter((v) => v != value);
    } else {
      ev.push(value);
    }

    setDaysSelected(ev);
  }

  function getReportDates(rdv, siteId) {
    setRdvSlots([]);
    setIsSaved(false);
    setIsLoadingSlots(true);
    setRdvSelected(null);
    setCurrentRdv(rdv);
    Axios.post(api_url + "slotcalendars/sites/" + siteId, {
      userRecipientId: recipientId,
      reportType: originSelected,
      cancelReason: reasonSelected,
      slotCalendarId: rdv.slotCalendarId,
    })
      .then((res) => {
        setRdvSlots(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoadingSlots(false);
      });
  }

  function saveReportRdv() {
    var slotObj = rdvSlots.find((s) => s.id == rdvSelected);

    setIsSaving(true);

    Axios.post(api_url + "slotcalendars/recipients/" + recipientId, {
      slotsId: slotObj.slotsId,
      start: slotObj.start,
      end: slotObj.end,
      slotCalendarId: currentRdv.slotCalendarId,
      reportType: originSelected,
      cancelReason: reasonSelected,
    })
      .then((res) => {
        getRdvs();
        getDatas();
        window.$("#rdv-modal").modal("hide");
        setIsSaved(true);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
          if (err.response.data.other) toast.error(err.response.data.other);
        }
      })
      .then(() => {
        setIsSaving(false);
      });
  }

  return (
    <>
      <ul className="list-group">
        {isLoading ? (
          <Loader />
        ) : (
          rdvs.map((rdv, rdvk) => (
            <li
              className="list-group-item p-0 mb-3 shadow-sm animated fadeInUp faster"
              style={{
                borderRadius: 8,
                overflow: "hidden",
                animationDelay: rdvk * 50 + "ms",
              }}
              key={"rdv" + rdvk}
            >
              <div
                className="text-center w-100 py-1 font-weight-bold d-flex flex-row align-items-center justify-content-between px-2"
                style={{
                  background: "#f1f1f1",
                  color: Colors.primary,
                }}
              >
                <span>
                  <i className="fa fa-calendar-alt mr-2"></i>
                  {DateTime.fromISO(rdv.date).toFormat("dd/MM/yyyy à hh:mm")}
                  {rdv.result && (
                    <span className="ml-2 text-secondary">| Terminé</span>
                  )}
                </span>
                {DateTime.fromISO(rdv.date) >= DateTime.local() && !rdv.result && (
                  <ModalBtn
                    id="rdv-modal"
                    btnClass={
                      "btn  btn-sm " +
                      (rdv.result ? "btn-light" : "btn-primary")
                    }
                    onClick={() => {
                      setCurrentRdv(rdv);
                      setRdvSlots(null);
                      setOriginSelected("1");
                      setReasonSelected(null);
                      setDaysSelected([]);
                    }}
                    label={"Reporter"}
                  />
                )}
              </div>
              <div className="p-3">
                <div className="">
                  Lieu :{" "}
                  <span className="text-primary">
                    {rdv.site.organization.name} - {rdv.site.name}
                  </span>
                </div>
                {rdv.comment && rdv.comment != "" && (
                  <div className=" p-1 mb-0">
                    <b className="mr-2" style={{ color: "#6f6f6f" }}>
                      <i className="far fa-comment mr-2" />
                      Votre commentaire -
                    </b>
                    <i>{rdv.comment}</i>
                  </div>
                )}
              </div>
              {rdv.result && (
                <div
                  style={{
                    background: "#eaeaea",
                    borderTop: "1px solid #d8d8d8",
                  }}
                >
                  <div className="pb-3 px-3 pt-1">
                    <div
                      className="font-weight-bold d-flex flex-column my-2"
                      style={{ fontSize: 15 }}
                    >
                      <span>
                        <i className="fa fa-arrow-right mr-2" />
                        Issue du RDV
                      </span>
                    </div>
                    <div className="">
                      Statut :{" "}
                      <b className="text-primary">
                        {
                          constants.RDV_CLOSE_TYPES.find(
                            (ct) => ct.id == rdv.result.status
                          ).name
                        }
                      </b>
                    </div>

                    {rdv.result.status == 2 && (
                      <div className="">
                        Réorientation vers :{" "}
                        <b className="text-primary">
                          {rdv.result.newOrientation}
                        </b>
                      </div>
                    )}
                    {rdv.result.status == 3 && (
                      <div className="">
                        Nouveau RDV prévu pour le :{" "}
                        <b className="text-primary">
                          {DateTime.fromISO(rdv.result.newDate).toFormat(
                            "dd/MM/yyyy hh:mm"
                          )}
                        </b>
                      </div>
                    )}
                    {rdv.result.status == 4 && (
                      <div>
                        <div className="">
                          Responsable de l'annulation :{" "}
                          <b className="text-primary">
                            {rdv.result.cancelOrigin
                              ? rdv.result.cancelOrigin
                              : "Inconnu"}
                          </b>
                        </div>
                        {rdv.result.cancelReason ? (
                          <div className="">
                            Motif :{" "}
                            <b className="text-primary">
                              {
                                constants.RDV_REPORT_TYPES.find(
                                  (r) => r.id == rdv.result.cancelReason
                                ).name
                              }
                            </b>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </li>
          ))
        )}
      </ul>
      <Modal
        id="rdv-modal"
        title="Reporter un rendez-vous"
        actions={
          <>
            {rdvSelected && (
              <SaveBtn
                save={saveReportRdv}
                isSaving={isSaving}
                isSaved={isSaved}
                text="Reporter le RDV à cette date"
                margin=""
              />
            )}
          </>
        }
      >
        {currentRdv && (
          <ChooseSiteReport
            getReportDates={(siteId) => getReportDates(currentRdv, siteId)}
            siteId={currentRdv.site.id}
            rdspId={rdspId}
          />
        )}
        {isLoadingSlots ? (
          <Loader />
        ) : !rdvSlots ? (
          <span className="text-black-50">
            Choisissez un site de RDV puis lancez la recherche.
          </span>
        ) : rdvSlots.length == 0 ? (
          errors.other ? (
            <span className="text-danger">{errors.other}</span>
          ) : (
            <span className="text-danger">
              Il n'y a pour l'instant aucun rendez-vous disponible, veuillez
              réessayer plus tard ou contacter votre agent référent.
            </span>
          )
        ) : (
          <>
            <Control
              label="Quel est le motif de ce report ?"
              name="reasonSelected"
              type="btnList"
              datas={constants.RDV_REPORT_TYPES}
              dataIndex="id"
              dataLabel="name"
              value={reasonSelected}
              change={(e) => setReasonSelected(e.target.value)}
              error={errors}
            />
            <Control
              label="Quels jours de la semaine êtes-vous disponible ?"
              type="checkBtnList"
              btnInline={true}
              name="days"
              datas={days}
              itemClassName={"btn-sm"}
              value={daysSelected}
              change={updateTimeSelected}
            />
            {daysSelected.length > 0 && (
              <Control
                label="Choisissez la date à laquelle vous souhaitez reporter"
                type="btnList"
                datas={rdvSlots.filter((s) => {
                  if (
                    daysSelected.includes(DateTime.fromISO(s.start).weekday)
                  ) {
                    return true;
                  }
                  return false;
                })}
                name="rdvSlot"
                change={(e) => setRdvSelected(e.target.value)}
                value={rdvSelected}
                dataIndex="id"
                dataLabel="label"
                error={errors}
              />
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default RecipientRdv;
