import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Card from "../../common/Card";
import RecipientTimeline from "../RecipientDetail/RecipientTimeline";
import Loader from "../../common/Loader";
import { api_url } from "../../../config";
import Axios from "axios";
import RecipientRdv from "../RecipientDetail/RecipientRdv";
import useStore from "../../../context/useStore";
import FormRDSP from "../FormRDSP/FormRDSP";
import FormRdv from "../FormRDSP/FormRdv";
import { toast } from "react-toastify";
import dataService from "../../../helpers/dataService";
import Orientation from "./Orientation";
import FormViewerPdf from "../FormRDSP/FormViewerPdf";
import ModifyPassword from "../ModifyPassword/ModifyPassword";
import Block from "./Block";
import ModifyUserInfos from "./ModifyUserInfos/ModifyUserInfos";
import CERPrint from "../CERPrint/CERPrint";
import useRecipientData from "../../../hooks/useRecipientData/useRecipientData";

var recipientOrigin = "";

const Dashboard = () => {
  const [state, dispatch] = useStore();
  var idToGet = state.auth.user.id;
  const [events, setEvents] = useState(false);
  const [rdvs, setRdvs] = useState(false);
  const [errors, setErrors] = useState({});
  const [rdspId, setRdspId] = useState(false);
  const [hasRdv, setHasRdv] = useState(false);
  const [hasRights, setHasRights] = useState(false);
  const [organism, setOrganism] = useState(false);
  const [showDl, setShowDl] = useState(false);
  const [form, setForm] = useState(false);
  const [answers, setAnswers] = useState(false);
  const [userData, setUserData] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRdvs, setIsLoadingRdvs] = useState(false);
  const [isLoadingRights, setIsLoadingRights] = useState(true);
  const [isLoadingOrganism, setIsLoadingOrganism] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  const [showDLCER, setShowDLCER] = useState(false);
  const { setRecipient } = useRecipientData();

  useEffect(() => {
    checkHasRights();
    getForm();
    getUserData();
  }, []);

  useEffect(() => {
    if (
      !isLoading &&
      !isLoadingRdvs &&
      !isLoadingOrganism &&
      !isLoadingRights
    ) {
      setAllLoaded(true);
    }
  }, [isLoading, isLoadingRdvs, isLoadingOrganism, isLoadingRights]);

  const getForm = () => {
    dataService.get("rdsp", setForm, setErrors, () => {});
  };
  const getUserData = () => {
    dataService.get(
      "recipients/me/",
      (data) => {
        setUserData(data);
        setRecipient(data);
        recipientOrigin = data.origin;
        getDatas();
      },
      setErrors,
      () => {}
    );
  };

  const checkHasRDSP = () => {
    Axios.get(api_url + "rdsp/mine/check")
      .then((res) => {
        var { rdsp, rdv } = res.data;
        if (!rdsp) {
          if (recipientOrigin != "STOCK") {
            alert(
              "Vous allez être redirigé vers le formulaire RDSP, merci de le compléter avant d'accéder à votre espace."
            );
            window.location.href = "/rdsp?redirectfrombo";
          }
        } else {
          setRdspId(rdsp);
        }
        if (rdv) {
          setHasRdv(true);
        } else {
          // toast.info(
          //   "Vous n'avez pas encore de rendez-vous, veuillez en prendre un à l'aide du formulaire ci-dessous"
          // );
        }
      })
      .catch((err) => {
        toast.error(
          "Une erreur s'est produite pendant la récupération de vos informations, essayez de vous déconnecter"
        );
      })
      .then(() => setIsLoading(false));
  };

  const checkHasRights = () => {
    setIsLoadingRights(true);
    dataService.get(
      "recipients/" + idToGet + "/rights",
      (datas) => {
        setHasRights(datas.isRightsOpen);
      },
      (errors) => {},
      () => {
        setIsLoadingRights(false);
      }
    );
  };

  function getDatas() {
    dataService.get("recipients/me/timeline", setEvents, setErrors, () => {
      checkHasRDSP();
    });
  }

  useEffect(() => {
    if (rdspId) {
      getOrientation();
      getRdsp();
    }
  }, [rdspId]);

  const getRdsp = () => {
    dataService.get("rdsp/" + rdspId, setAnswers, setErrors, () => {});
  };

  function getOrientation() {
    setIsLoadingOrganism(true);
    dataService.get(
      `rdsp/${rdspId}/orientation`,
      (d) => setOrganism(d.organization),
      setErrors,
      () => setIsLoadingOrganism(false)
    );
  }

  useEffect(() => {
    if (hasRdv) getRdvs();
  }, [hasRdv]);

  function getRdvs() {
    setIsLoadingRdvs(true);
    dataService.get("rdv/mine", setRdvs, setErrors, () => {
      setIsLoadingRdvs(false);
    });
  }

  const setRdvTaken = () => {
    getDatas();
  };

  return (
    <Page title="" errors={errors}>
      {!allLoaded ? (
        <Loader text="Veuillez patienter, nous préparons votre espace" />
      ) : (
        <div className="row">
          {!hasRdv &&
          rdspId &&
          state.auth.user &&
          !isLoading &&
          !isLoadingOrganism ? (
            <div className="col-12">
              <FormRdv
                rdspId={rdspId}
                userData={state.auth.user}
                setRdvTaken={setRdvTaken}
                hasDroit={hasRights}
              />
            </div>
          ) : null}
          {rdspId && form && answers && userData && (
            <Block
              colSize="col-12 mt-5"
              appearEffect="fadeInRight"
              title={
                <>
                  Vos données <strong>socio-professionnelles</strong>
                </>
              }
              delay={200}
            >
              <button
                className="btn btn-default bg-white animated zoomIn faster"
                onClick={() => setShowDl(true)}
              >
                <i className="fa fa-download mr-2" />
                Télécharger mon formulaire RDSP
              </button>
              {showDl && (
                <FormViewerPdf
                  form={answers.config}
                  answers={answers.rdsp}
                  recipient={userData}
                />
              )}
            </Block>
          )}
          {organism && (
            <Block
              colSize="col-12 "
              appearEffect="fadeInLeft"
              title={
                <>
                  Vous avez été orienté vers <strong>{organism.name}</strong>
                </>
              }
            >
              <Orientation
                organism={organism}
                recipientId={state.auth.user.id}
              />
            </Block>
          )}

          {userData && (
            <Block
              colSize="col-12"
              appearEffect="fadeInLeft"
              title={
                <>
                  Votre <strong>Contrat d'Engagement Réciproque</strong>
                </>
              }
              delay={200}
            >
              <button
                className="btn btn-primary  animated zoomIn faster"
                onClick={() => setShowDLCER(true)}
              >
                <i className="fa fa-download mr-2" />
                Consulter mon CER
              </button>
              {showDLCER && <CERPrint recipient={userData} />}
            </Block>
          )}
          <Block
            colSize="col-12"
            appearEffect="fadeInRight"
            title={
              <>
                Votre <strong>compte</strong>
              </>
            }
            delay={200}
          >
            <div className="d-center">
              <ModifyUserInfos userData={userData} setUserData={setUserData} />
            </div>
            {!showPassword && (
              <button
                className="btn btn-default bg-white animated zoomIn faster"
                onClick={() => setShowPassword(true)}
              >
                <i className="fa fa-lock mr-2" />
                Changer mon mot de passe
              </button>
            )}
            {showPassword && (
              <ModifyPassword
                route={"recipients/me/password"}
                showPassword={showPassword}
                setShowPassword={setShowPassword}
              />
            )}
          </Block>

          {(isLoadingRights || isLoadingRdvs) && <Loader />}
          {hasRdv && rdvs && (
            <div className="col-12 my-4">
              <h4>Vos rendez-vous</h4>

              <RecipientRdv
                rdvs={rdvs}
                errors={errors}
                setErrors={setErrors}
                recipientId={idToGet}
                getDatas={getDatas}
                getRdvs={getRdvs}
                isLoading={isLoadingRdvs}
                rdspId={rdspId}
              />
            </div>
          )}
          <div className="col-12 my-4">
            <h4 className="text-center">
              Votre <strong>parcours</strong>
            </h4>
            {events ? <RecipientTimeline events={events} /> : <Loader />}
          </div>

          {/* {isLoadingRights ? (
          <Loader />
        ) : hasRights ? (
          <>Vous avez les droits</>
        ) : (
          <>Vous n'avez pas les droits</>
        )} */}
        </div>
      )}
    </Page>
  );
};

export default Dashboard;
