import React from "react";
import { ClipLoader } from "react-spinners";

const SaveBtn = ({
  save,
  isSaving,
  isSaved,
  text = "Enregistrer",
  margin = "mt-5",
}) => {
  return (
    <button
      className={"d-flex btn justify-content-center btn-success " + margin}
      onClick={save}
      disabled={isSaving}
    >
      {isSaving && <ClipLoader size={19} color={"#ffffff"} />}
      {isSaved && !isSaving && <i className="fa fa-check" />}
      {!isSaving && !isSaved && text}
    </button>
  );
};

export default SaveBtn;
