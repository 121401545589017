import Axios from "axios";
import React, { useEffect, useState } from "react";
import { api_url } from "../../../config";
import Control from "../../common/Control";

const ChooseSiteReport = ({ getReportDates, rdspId, siteId }) => {
  const [sites, setSites] = useState([]);
  const [errors, setErrors] = useState({});
  const [site, setSite] = useState(siteId);

  useEffect(() => {
    Axios.get(api_url + "rdsp/" + rdspId + "/orientation")
      .then((res) => {
        setSites(res.data.sites);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      });
  }, []);

  return (
    <div className="custom-card my-2">
      <Control
        label="Choisissez un site de RDV"
        type="select"
        value={site}
        datas={sites}
        change={(e) => setSite(e.target.value)}
        dataIndex="id"
        dataLabel="name"
        error={errors}
        margin={"mb-2"}
      />
      {site && (
        <button
          className="btn btn-primary w-100"
          onClick={() => getReportDates(site)}
        >
          Chercher des RDV pour le site sélectionné
        </button>
      )}
    </div>
  );
};

export default ChooseSiteReport;
