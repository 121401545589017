import { atom } from "jotai";

export const cerObjectivesAtom = atom([]);
export const cerStepsAtom = atom([]);
export const modifiedObjectiveIdAtom = atom(null);
export const modifiedStepIdAtom = atom(null);
export const objectiveCategoriesAtom = atom([]);
export const pathTypesAtom = atom([]);
export const operatorsAtom = atom([]);
export const cerObstaclesAtom = atom([]);
