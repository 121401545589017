import { atom, useAtom } from "jotai";

const recipientAtom = atom(null);

const useRecipientData = () => {
  const [recipient, setRecipient] = useAtom(recipientAtom);
  return {
    recipient,
    setRecipient,
  };
};

export default useRecipientData;
