import React, { useEffect, useRef, useState } from "react";
import { authActions } from "../../../context/actions/authActions";
import useStore from "../../../context/useStore";
import Control from "../../common/Control";
import GroupedDigitControl from "../../common/GroupedDigitControl";
import Loader from "../../common/Loader";
import SaveBtn from "../../common/SaveBtn";
import Timer from "../../common/Timer/Timer";
import Page from "../../common/layout/Page";
import ForgotPassword from "./ForgotPassword";
import { Captcha, captchaSettings } from "reactjs-captcha";
import { api_url } from "../../../config";

captchaSettings.set({
  captchaEndpoint: `${api_url}simple-captcha-endpoint`,
});

const Login = () => {
  const [state, dispatch] = useStore();
  const captchaRef = useRef();
  var actions = authActions(state, dispatch);
  const [nir, setNir] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [captchaToken, setCaptchaToken] = useState(false);
  const [timer, setTimer] = useState(0);
  const [canLoggin, setCanLoggin] = useState(true);

  useEffect(() => {
    if (Object.values(state.errors).length > 0) {
      setIsLoading(false);

      if (captchaRef.current) {
        try {
          captchaRef.current.reloadImage();
        } catch (err) {}
      }
      if (state.errors.password) {
        setPassword("");
      }
      if (state.errors.timer) {
        setTimer(parseInt(state.errors.timer));
      }
    }
  }, [state.errors]);

  useEffect(() => {
    setTimer(false);
    setCanLoggin(true);
  }, [forgotPassword]);

  useEffect(() => {
    if (state.errors.login || state.errors.password) {
      setIsLoading(false);
    }
  }, [state.errors]);

  useEffect(() => {
    if (timer > 0) {
      setCanLoggin(false);
    }
  }, [timer]);

  function timerFinish() {
    setCanLoggin(true);
  }

  function loginHandle(e) {
    setIsLoading(true);
    e.preventDefault();
    let postData = {
      login: nir,
      password,
      captcha: null,
    };
    // Récupération du code captcha saisi par
    if (captchaRef.current) {
      let userEnteredCaptchaCode =
        captchaRef.current.getUserEnteredCaptchaCode();
      // Récupération de l'id du captcha généré par la librairie
      let captchaId = captchaRef.current.getCaptchaId();

      //Objet envoyé au backend pour valider le captcha contenant l'id et le code du captcha
      postData.captcha = { code: userEnteredCaptchaCode, id: captchaId };
    }
    actions.loginUser(postData);
  }

  return (
    <Page>
      {/* <div className="text-center">
        <img
          style={{ maxHeight: 200 }}
          className="img-fluid"
          src="/assets/logo.jpeg"
        />
      </div> */}
      {!forgotPassword ? (
        <form
          onSubmit={loginHandle}
          className="card m-auto col-md-6 col-lg-6 col-12 px-0 pb-4 animated fadeInTop overflow-hidden"
        >
          <div className="card-header">
            <h1 className="mb-0">Espace allocataire</h1>
          </div>
          <div className="card-body px-4 px-lg-5 px-md-4 pb-2">
            <div className="row m-0">
              <div className="col-12 col-md-6 col-lg-6">
                <img
                  style={{ maxHeight: 196 }}
                  className="img-fluid mb-3"
                  src="/assets/carte-vitale.png"
                  alt="spécimen de carte vitale"
                />
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <p className="text-primary">
                  Votre numéro de sécurité sociale figure sur la face recto de
                  votre carte vitale (cf image).
                </p>
              </div>
            </div>
            <label className="pb-2 mb-1">Numéro de sécurité sociale</label>
            {/* <DigitControl
              params={[
                { size: 1, value: "" },
                { size: 2, value: "" },
                { size: 5, value: "" },
                { size: 2, value: "" },
                { size: 3, value: "" },
                { size: 2, value: "" },
              ]}
              name={"nir"}
              complete={(e) => setNir(e.target.value)}
              value={nir}
            /> */}
            <GroupedDigitControl
              name="nir"
              value={nir}
              separation={[1, 2, 2, 2, 3, 3, 2]}
              change={(e) => setNir(e.target.value)}
              separator={"   "}
            />

            <span className="text-danger d-block mt-2 text-center">
              {state.errors.login}
            </span>

            <Control
              label="Mot de passe"
              name="password"
              id="password"
              type="password"
              value={password}
              change={(e) => setPassword(e.target.value)}
              error={state.errors}
              margin={"mt-4"}
            />
            <div className="text-center d-flex flex-column justify-content-center align-items-center">
              {state.errors.nbAttempts > 0 && (
                <span className="text-primary">
                  {state.errors.nbAttempts} tentatives restantes
                </span>
              )}
              {!canLoggin && (
                <>
                  <span className="text-danger mb-3">
                    L'accès à votre compte est temporairement bloqué suite à
                    plusieurs tentatives de connexion infructueuses.
                  </span>
                  <Timer timeLimit={timer} timerFinish={timerFinish} />
                </>
              )}
            </div>
          </div>
          <div className="text-center m-auto">
            {Object.values(state.errors).length > 0 && (
              <>
                <Captcha captchaStyleName="captchaFR" ref={captchaRef} />
                <label className="w-100 mt-2">
                  <input
                    className="form-control d-block "
                    id="captchaFormulaireExtInput"
                    type="text"
                    placeholder="Saisissez le code ci-dessus"
                  />
                </label>
                {state.errors.captcha && (
                  <span className="text-danger">{state.errors.captcha}</span>
                )}
              </>
            )}
          </div>

          <div className="text-center m-auto">
            {state.auth.isLoading ? (
              <Loader />
            ) : (
              canLoggin && (
                <SaveBtn
                  className="animated zoomIn"
                  isSaving={isLoading}
                  text="Connexion"
                  type="primary"
                  typeBtn="submit"
                  margin=""
                />
              )
            )}
          </div>
          <div className="text-center mt-2">
            <button
              type="button"
              onClick={() => setForgotPassword(true)}
              className="btn btn-link"
            >
              J'ai oublié mon mot de passe
            </button>
          </div>
        </form>
      ) : (
        <ForgotPassword setForgotPassword={setForgotPassword} nir={nir} />
      )}
    </Page>
  );
};

export default Login;
