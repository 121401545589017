import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { useContext } from "react";
import colors from "../../../helpers/colors";
import useCERData from "../../../hooks/useCERData/useCERData";
import { GoalContext } from "./GoalContext";
import OperatorsList from "./OperatorsList";
import { StepContext } from "./StepContext";

const StepsList = () => {
  const { cerSteps } = useCERData();
  const objective = useContext(GoalContext);

  var styles = StyleSheet.create({
    container: {
      paddingLeft: 20,
      paddingBottom: 4,
    },
    title: {
      fontSize: 10,
      color: colors.primary,
      paddingBottom: 3,
    },
  });

  return objective.steps.map((step, sk) => {
    var stepRef = cerSteps.find((o) => o.id == step.step);
    var stepNum = parseInt(sk) + 1;
    return (
      <View style={{ ...styles.container }}>
        <Text style={{ ...styles.title }}>{stepRef?.name}</Text>
        <StepContext.Provider value={step}>
          <OperatorsList />
        </StepContext.Provider>
      </View>
    );
  });
};

export default StepsList;
