import jwt_decode from "jwt-decode";
import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import PrivateRoute from "./components/common/PrivateRoute";
// Pages
import Axios from "axios";
import { Settings } from "luxon";
import CookieConsent from "react-cookie-consent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/common/Loader";
import Footer from "./components/common/layout/Footer";
import Header from "./components/common/layout/Header";
import ActivateAccount from "./components/pages/ActivateAccount/ActivateAccount";
import ConfirmEpPresence from "./components/pages/ConfirmEpPresence/ConfirmEpPresence";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import FormRDSP from "./components/pages/FormRDSP/FormRDSP";
import NotFound from "./components/pages/NotFound/NotFound";
import PassOrientation from "./components/pages/PassOrientation/PassOrientation";
import RenewPassword from "./components/pages/RenewPassword/RenewPassword";
import UserDetail from "./components/pages/UserDetail/UserDetail";
import UserList from "./components/pages/UserList/UserList";
import { api_url } from "./config";
import { authActions } from "./context/actions/authActions";
import { constantActions } from "./context/actions/constantActions";
import { AUTH_TYPES } from "./context/reducers/authReducers";
import useStore from "./context/useStore";

Settings.defaultZoneName = "Indian/Reunion";

var firstLoad = true;
const App = () => {
  const [state, dispatch] = useStore();
  var actions = authActions(state, dispatch);
  var c_actions = constantActions(state, dispatch);
  Axios.defaults.withCredentials = true;
  Axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response && error.response.status == 401) {
        if (error.response.config.url !== api_url + "auth/logout") {
          actions.logoutUser();
        }
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );

  if (localStorage.jwtToken && firstLoad) {
    firstLoad = false;
    // Set auth token header auth
    //tools.setAuthToken(localStorage.jwtToken);
    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtToken);

    // Set user and isAuthenticated
    dispatch({
      type: AUTH_TYPES.SET_CURRENT_USER,
      payload: decoded,
    });

    // Check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // Logout user

      actions.logoutUser();
      // Clear current Profile
      // store.dispatch(clearCurrentProfile())
      // Redirect to login
    }
  }
  useEffect(() => {
    if (!localStorage.jwtToken) {
      Axios.get(api_url + "auth/me")
        .then((res) => {
          localStorage.setItem("jwtToken", res.data);
          const decoded = jwt_decode(res.data);
          dispatch({
            type: AUTH_TYPES.SET_CURRENT_USER,
            payload: decoded,
          });
        })
        .catch((err) => {
          actions.logoutUser();
        });
    }
    c_actions.getConstants();
  }, []);

  const menuItems = [{ name: "Utilisateurs", path: "users" }];

  return (
    <Router>
      <>
        <CookieConsent
          location="bottom"
          buttonText="J'ai compris"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#0f6eb4" }}
          buttonStyle={{
            color: "white",
            fontSize: "13px",
            background: "#1d9e14",
          }}
          expires={150}
        >
          Ce site utilise un cookie de connexion pour authentifier les
          utilisateurs.{" "}
        </CookieConsent>
        <Header displayOnPublicPages={true} items={menuItems} />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        {Object.keys(state.constants.items).length ? (
          <Switch>
            <Route path="/rdsp" exact component={FormRDSP} />

            <Route
              path="/renew-password/:token/:email"
              exact
              component={RenewPassword}
            />
            <Route
              path="/activate-account/:token/:email"
              exact
              component={ActivateAccount}
            />
            <PrivateRoute path="/" exact component={Dashboard} />
            <PrivateRoute path="/users" exact component={UserList} />
            <PrivateRoute path="/users/:id" exact component={UserDetail} />
            <PrivateRoute
              path="/passorientation/:id"
              exact
              component={PassOrientation}
            />
            <Route
              path="/ep-confirm-presence/:token/:epid"
              exact
              component={ConfirmEpPresence}
            />
            <Route component={NotFound} />
          </Switch>
        ) : (
          <Loader />
        )}

        <Footer />
      </>
    </Router>
  );
};

export default App;
