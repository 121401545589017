import React, { useState } from "react";
import ModifyEmail from "./ModifyEmail";
import UserInfosDisplay from "./UserInfosDisplay";

const ModifyUserInfos = ({ userData, setUserData }) => {
  const [editEmail, setEditEmail] = useState(false);

  return (
    <div className="mb-2 w-100">
      <UserInfosDisplay userData={userData} />
      {/* {editEmail && <ModifyEmail setEditEmail={setEditEmail} />}
      {!editEmail && (
        <div className="d-center mt-2">
          <button
            className="btn btn-default bg-white animated zoomIn faster"
            onClick={() => setEditEmail(true)}
          >
            <i className="fa fa-at mr-2" />
            Changer mon email
          </button>
        </div>
      )} */}
    </div>
  );
};

export default ModifyUserInfos;
