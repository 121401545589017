import React from "react";

const LandingML = ({url}) => {
  return (
    <div className="col-12 col-lg-6 col-md-6 m-auto px-0 text-center">
      <div className="card">
        <div className="card-body">
          <i className="far fa-check-circle text-success fa-3x mb-3 animated  rotateIn delay-1s" />
          <p className="">
            Vous allez bénéficier d'un accompagnement personnalisé de Mission
            Locale.<br/>
            Vous pouvez procéder à votre préinscription en ligne dès maintenant sur <a href={url}>{url}</a>
          </p>

          <p className="text-danger">
            Il est important de vous rendre au rendez-vous qui vous sera proposé
            pour maintenir le versement de votre RSA.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingML;
