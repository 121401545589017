import React from "react";
import Loader from "../Loader";

const LoaderFinish = ({ text }) => {
  return (
    <div className="card">
      <div className="card-body btn-success text-center">
        <Loader color="white" />
        <p>{text}</p>
      </div>
    </div>
  );
};

export default LoaderFinish;
