import React from "react";

const ProgressBar = ({ progress, text }) => {
  return (
    <div
      className="progress_container mt-3"
      style={{
        background: "#f3f3f3",
        display: "flex",
        position: "relative",
        borderRadius: 5
      }}
    >
      <div
        style={{
          background: "#2598f4",
          width: progress + "%",
          height: 3,
          borderRadius: 6,
          transition: "1s"
        }}
        className="progress_bar"
      ></div>
      <div className="progress_text">{text}</div>
    </div>
  );
};

export default ProgressBar;
