import React, { useState } from "react";
import PassOrientation from "../PassOrientation/PassOrientation";

const Orientation = ({ organism, recipientId }) => {
  const [showDl, setShowDl] = useState(false);

  return (
    <>
      {!showDl && (
        <button
          className="btn btn-primary animated zoomIn faster"
          onClick={() => setShowDl(true)}
          target="_BLANK"
        >
          <i className="fa fa-download mr-2" />
          {showDl ? (
            <i className="fa fa-arrow-down" />
          ) : (
            "Consulter mon PASS ORIENTATION"
          )}
        </button>
      )}

      {showDl && (
        <>
          <div
            className="w-100"
            style={{
              background: "rgb(236, 236, 236)",
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
            }}
          >
            <PassOrientation />
          </div>
        </>
      )}
    </>
  );
};

export default Orientation;
