import React, { useState, useEffect } from "react";
import Control from "../../common/Control";
import PasswordControl from "../../common/PasswordControl";
import Page from "../../common/layout/Page";
import Axios from "axios";
import { api_url } from "../../../config";
import Loader from "../../common/Loader";
import { Link } from "react-router-dom";
import Contact from "../../common/Contact/Contact";

const RenewPassword = (props) => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errors, setErrors] = useState({});
  const [canSave, setCanSave] = useState(false);
  const [passwordsEqual, setPasswordsEqual] = useState(false);
  const [resetValid, setResetValid] = useState(false);
  const [checkingToken, setCheckingToken] = useState(false);
  const [isMissingParam, setIsMissionParam] = useState(false);

  useEffect(() => {
    if (!props.match.params.token || !props.match.params.email) {
      setIsMissionParam(true);
    }
  }, []);

  useEffect(() => {
    setErrors({});
    var errs = {};
    if (password != passwordConfirm || password.length == 0) {
      errs.passwordConfirm = "Doit-être identique au premier";
    }

    if (Object.keys(errs).length === 0 && errs.constructor === Object) {
      setPasswordsEqual(true);
    } else {
      setPasswordsEqual(false);
    }
    setErrors(errs);
  }, [password, passwordConfirm]);

  function save() {
    if (canSave && passwordsEqual) {
      //save
      setCheckingToken(true);
      Axios.post(api_url + "recipients/renew-password/", {
        token: props.match.params.token,
        email: props.match.params.email,
        password: password,
      })
        .then((res) => {
          setCheckingToken(false);

          setResetValid(true);
        })
        .catch((err) => {
          setCheckingToken(false);
          setResetValid(false);
          if (err.response && err.response.data) setErrors(err.response.data);
        });
    }
  }

  return (
    <Page>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 m-auto">
          <div className="card">
            <div className="card-header">
              <h1>Changer mon mot de passe</h1>
            </div>
            {checkingToken ? (
              <div className="card-body  p-4 p-lg-5 p-md-4">
                <Loader />
              </div>
            ) : isMissingParam ? (
              <div className="card-body  p-4 p-lg-5 p-md-4">
                <p className="mb-0 text-danger">Ce lien est incorrect.</p>
              </div>
            ) : resetValid ? (
              <div className="card-body  p-4 p-lg-5 p-md-4 text-center">
                <p className="mb-4 text-success">
                  Votre mot de passe a bien été modifié, vous pouvez à présent
                  vous connecter.
                </p>
                <Link className="btn btn-link" to="/">
                  Se connecter
                </Link>
              </div>
            ) : (
              <div className="card-body p-4">
                <p>
                  Veuillez saisir un nouveau mot de passe pour votre compte et
                  le confirmer à l'aide des champs ci-dessous.
                </p>
                <Control
                  className="mt-5"
                  label="Choisissez un mot de passe"
                  type="password"
                  name="password"
                  value={password}
                  change={(e) => setPassword(e.target.value)}
                  error={errors}
                  isValid={!errors.password && password != "" && canSave}
                />
                <PasswordControl
                  style={{ marginTop: -32 }}
                  password={password}
                  minLength={9}
                  minUppercase={1}
                  minLowercase={3}
                  minNumber={1}
                  setCanSave={setCanSave}
                />
                <Control
                  label="Confirmez votre mot de passe"
                  type="password"
                  name="passwordConfirm"
                  value={passwordConfirm}
                  change={(e) => setPasswordConfirm(e.target.value)}
                  error={errors}
                  isValid={!errors.passwordConfirm && passwordConfirm != ""}
                />
                <span className="text-danger">{errors.other}</span>
                {canSave && passwordsEqual && (
                  <button
                    className={
                      "btn mt-3 w-100 btn-success animated zoomIn faster"
                    }
                    onClick={save}
                  >
                    Mettre à jour mon mot de passe
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        <Contact />
      </div>
    </Page>
  );
};

export default RenewPassword;
