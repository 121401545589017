import React from "react";

const ModalBtn = ({
  id,
  label,
  btnClass,
  onClick = false,
  noBackdrop = false,
}) => {
  return (
    <button
      type="button"
      className={btnClass}
      data-toggle="modal"
      data-target={"#" + id}
      onClick={onClick}
      data-backdrop={noBackdrop ? "false" : "true"}
    >
      {label}
    </button>
  );
};

export default ModalBtn;
