import React, { useState } from "react";
import Control from "./Control";

const GroupedDigitControl = ({
  name,
  change,
  value,
  separation,
  separator,
  placeholder,
  type = "",
  className,
  autoFocus = false,
}) => {
  const [formatedValue, setFormatedValue] = useState("");

  function updateInput(e) {
    var { value } = e.target;
    var nirconcat = e.target.value.split(separator).join("");
    var newnir = "";
    var curr = 0;
    for (var key in separation) {
      var ns = separation[key] + curr;
      if (curr < nirconcat.length) {
        newnir += nirconcat.substring(curr, ns);
        var newnirsp = newnir.split(separator);
        if (
          newnirsp[newnirsp.length - 1].length == separation[key] &&
          newnirsp.length < separation.length
        ) {
          newnir += separator;
        }
      }

      curr = ns;
    }
    if (newnir.substr(newnir.length - separator.length) == separator) {
      newnir = newnir.substring(0, newnir.length - separator.length);
    }
    setFormatedValue(newnir);

    var upval = nirconcat.substring(0, curr);
    if (type == "date") {
      upval = newnir.split(separator)[2];
      upval += "-" + newnir.split(separator)[1];
      upval += "-" + newnir.split(separator)[0];
    }
    change({ target: { name: name, value: upval } });
  }
  return (
    <input
      id="nir-login-inp"
      type="text"
      className={"form-control " + className}
      name={name}
      value={formatedValue}
      onChange={updateInput}
      placeholder={placeholder}
      autoFocus={autoFocus}
    />
  );
};

export default GroupedDigitControl;
