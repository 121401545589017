import React, { useEffect, useState } from "react";
import Loader from "../../common/Loader";
import {
  PDFViewer,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import useCERModule from "../../../hooks/useCERModule/useCERModule";
import { useParams } from "react-router-dom";
import useStore from "../../../context/useStore";
import useCERData from "../../../hooks/useCERData/useCERData";
import tools from "../../../helpers/tools";
import PathTypesList from "./PathTypesList";
import LegalText from "./LegalText";
import ContractInfo from "./ContractInfo";
import Signatures from "./Signatures";
import RecipientCERInfo from "./RecipientCERInfo";

const Section = ({ wrap = true, style = {}, children }) => {
  var styles = StyleSheet.create({
    section: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 20,
      paddingRight: 20,
      ...style,
    },
  });
  return (
    <View style={{ ...styles.section }} wrap={wrap}>
      {children}
    </View>
  );
};

const MyDoc = ({ constants }) => {
  var styles = StyleSheet.create({
    logoContainer: {
      position: "absolute",
      top: 20,
      left: 20,
    },
    logo: {
      height: 78,
      width: 54,
    },

    title: {
      paddingBottom: 0,
      paddingRight: 20,
      fontSize: 13,
      paddingLeft: 70,
      paddingTop: 20,
      textAlign: "center",
    },
  });

  return (
    <Document>
      <Page wrap size="A4" orientation="portrait">
        <View
          style={{
            ...styles.logoContainer,
          }}
        >
          <Image src="/assets/logo-espoar.png" style={{ ...styles.logo }} />
        </View>

        <View style={{ ...styles.title }}>
          <Text>Contractualisation du parcours d'accompagnement défini entre les signataires</Text>
          <Text>Contrat d'Engagements Réciproques</Text>
          <Text>« notre CER »</Text>
        </View>

        <Section>
          <RecipientCERInfo />
        </Section>

        <Section>
          <Text style={{ fontSize: 13 }}>Nos objectifs partagés :</Text>
          <PathTypesList constants={constants} />
        </Section>

        <Section wrap={false}>
          <LegalText />
        </Section>

        <Section wrap={false}>
          <ContractInfo CER_DURATIONS={constants.CER_DURATIONS} />
        </Section>

        <Section wrap={false}>
          <Signatures />
        </Section>
      </Page>
    </Document>
  );
};

const CERPrint = ({ recipient }) => {
  const [state] = useStore();
  const constants = state.constants.items;
  const { CER_DURATIONS, CER_GOAL_CATEGORIES } = constants;
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [pdfKey, setPdfKey] = useState(1);
  const [docReady, setDocReady] = useState(false);
  const [displayPreview, setDisplayPreview] = useState(false);

  const {
    setObjectiveCategories,
    getCerObjectives,
    getCerSteps,
    getCerOperators,
    setPathTypes,
    cerObjectives,
    cerSteps,
    operators,
    pathTypes,
  } = useCERData();

  const { isLoadingCER, noCER, actions } = useCERModule();

  useEffect(() => {
    setIsLoading(true);
    // document.querySelector("#sidebar").style.display = "none";
    // document.querySelector("nav.navbar").style.display = "none";
    // document.querySelector("#sidebarCollapse").style.display = "none";

    actions.getRecipientCER(recipient.id);
    getCerObjectives();
    getCerSteps();
    getCerOperators();
    setObjectiveCategories(state.constants.items.CER_GOAL_CATEGORIES);
    setPathTypes(state.constants.items.CER_GOAL_PATH_TYPES);
  }, []);

  useEffect(() => {
    if (
      !isLoadingCER &&
      cerObjectives.length > 0 &&
      cerSteps.length > 0 &&
      operators.length > 0 &&
      pathTypes.length > 0 &&
      recipient
    ) {
      setIsLoading(false);
    }
  }, [isLoadingCER, cerObjectives, cerSteps, operators, pathTypes, recipient]);

  return (
    <div key={pdfKey} className="w-100">
      <img src="/assets/logo-po.jpg" width="1" />

      {isLoading ? (
        <Loader />
      ) : noCER ? (
        <p className="text-center">Vous n'avez encore aucun contrat CER établi.</p>
      ) : (
        <div
          className="text-center pt-3  pb-3 px-3  d-flex justify-content-center align-items-center flex-column"
          style={{ gap: "1rem" }}
        >
          <PDFDownloadLink
            className="btn btn-primary dl-po-btn mb-2"
            document={<MyDoc constants={{ CER_DURATIONS, CER_GOAL_CATEGORIES }} />}
            fileName={`Contrat CER ${tools.getFullname(recipient)}.pdf`}
          >
            {({ blob, url, loading, error }) => {
              var text = loading ? (
                "Chargement..."
              ) : (
                <>
                  <i className="fa fa-download mr-2" />
                  Télécharger le Contrat CER
                </>
              );
              if (error) {
                setPdfKey(pdfKey + 1);
              }
              if (!loading) {
                setDocReady(true);
              }
              return <>{text}</>;
            }}
          </PDFDownloadLink>
          {docReady && (
            <button
              className="btn btn-info mb-2 animated zoomIn faster"
              onClick={() => setDisplayPreview(!displayPreview)}
            >
              <i className={`fa mr-2 ${displayPreview ? "fa-eye-slash" : "fa-eye"}`} />
              {displayPreview ? "Fermer" : "Voir dans"} la visionneuse
            </button>
          )}
          {displayPreview && (
            <div
              className="animated fadeIn fast"
              style={{
                width: "100%",
                height: 600,
              }}
            >
              <PDFViewer style={{ width: "100%", height: "100%", border: 0 }}>
                <MyDoc constants={{ CER_DURATIONS, CER_GOAL_CATEGORIES }} />
              </PDFViewer>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CERPrint;
