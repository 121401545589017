import React from "react";

const Contact = () => {
  return null;
  // return (
  //   <div
  //     className="col-12 text-center border p-4  mx-auto mt-5 text-primary"
  //     style={{ borderRadius: 5 }}
  //   >
  //     En cas de problème, contactez l'assistance
  //   </div>
  // );
};

export default Contact;
