import React from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";

const Header = withRouter((props) => {
  const [state, dispatch] = useStore();
  const actions = authActions(state, dispatch);
  var authData = state.auth;
  return authData.isAuthenticated ? (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark px-4">
        <span className="navbar-brand py-2 d-flex align-items-center">
          <img className="mr-2" height="30" src="/assets/logo-espoar-new.png" />
        </span>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#menu"
          aria-controls="menu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="menu">
          <ul className="navbar-nav mr-auto">
            {/* <li className="nav-item">
              <NavLink className="nav-link" to="/users">
                Les utilisateurs
              </NavLink>
            </li> */}
          </ul>
          <div className="form-inline text-white">
            <span
              className="mr-2"
              style={{
                textTransform: "capitalize",
                fontWeight: "800",
              }}
            >
              {authData.user.firstname}{" "}
              <span style={{ textTransform: "uppercase" }}>{authData.user.lastname}</span>
            </span>
            <button className="btn text-white" onClick={actions.logoutUser}>
              <i className="fa fa-power-off" />
            </button>
          </div>
        </div>
      </nav>
    </>
  ) : props.displayOnPublicPages ? (
    <nav className="navbar navbar-expand-lg navbar-dark px-4 py-0">
      <span className="navbar-brand py-2 d-flex align-items-center">
        <img className="mr-2" height="30" src="/assets/logo-espoar-new.png" />
      </span>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#menu"
        aria-controls="menu"
        aria-expanded="false"
        aria-label="Se déconnecter"
        title="Se déconnecter"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="menu">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item"></li>
        </ul>
      </div>
    </nav>
  ) : (
    <></>
  );
});

export default Header;
