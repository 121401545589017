import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../helpers/dataService";
import Control from "../../common/Control";
import PasswordControl from "../../common/PasswordControl";
import SaveBtn from "../../common/SaveBtn";

const ModifyPassword = ({ route, showPassword, setShowPassword }) => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [passwordsIdentical, setPasswordsIdentical] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const save = () => {
    setIsLoading(true);
    dataService.patch(
      route,
      { password },
      (datas) => {
        setIsLoading(false);
        toast.success(
          "Votre mot de passe a bien été modifié, utilisez le lors de vos prochaine connexions"
        );
        setShowPassword(false);
      },
      (error) => {
        setErrors(error);
      },
      () => setIsLoading(false)
    );
  };

  useEffect(() => {
    if (password == passwordConfirm) {
      setPasswordsIdentical(true);
    } else {
      setPasswordsIdentical(false);
    }
  }, [password, passwordConfirm]);

  return (
    <>
      {showPassword && (
        <div className="card p-3 text-left col-12 mx-auto">
          <button
            className="btn  btn-link d-flex align-items-center px-0"
            onClick={() => setShowPassword((p) => !p)}
          >
            {showPassword ? (
              <>
                <i className="fa fa-arrow-left mr-2" />
                Annuler
              </>
            ) : (
              "Modifier le mot de passe"
            )}
          </button>
          <Control
            margin="mt-2"
            label="Nouveau mot de passe"
            type="password"
            value={password}
            change={(e) => setPassword(e.target.value)}
            error={errors}
            setErrors={setErrors}
          />
          <PasswordControl
            password={password}
            minLength={9}
            minUppercase={1}
            minLowercase={3}
            minNumber={1}
            setCanSave={setCanSave}
          />
          <Control
            margin="my-0"
            label="Confirmez le mot de passe"
            type="password"
            value={passwordConfirm}
            change={(e) => setPasswordConfirm(e.target.value)}
            error={errors}
            setErrors={setErrors}
          />
          {passwordsIdentical && canSave ? (
            <SaveBtn
              margin="mt-3"
              save={save}
              text="Enregistrer le mot de passe"
              isSaving={isLoading}
            />
          ) : (
            <div className="alert alert-warning mt-2">
              Les 2 mots de passe doivent être identiques
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ModifyPassword;
