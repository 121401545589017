import React from "react";

const LandingCD = () => {
  return (
    <div className="col-12 col-lg-6 col-md-6 m-auto px-0 text-center">
      <div className="card">
        <div className="card-body">
          <img src="/assets/logo-cd.png" />
          <p className="mt-3">
            Vous allez bénéficier d'un accompagnement personnalisé du Conseil
            Départemental qui vous contactera dans les meilleurs délais.
          </p>

          <p className="text-danger">
            Il est important de vous rendre au rendez-vous qui vous sera proposé
            pour maintenir le versement de votre RSA.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingCD;
