import React, { useEffect, useState } from "react";
import tools from "../../../helpers/tools";

const LoadLSOverlay = ({ setAnswers, setStep }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    getAnswersFromLocalStorage();
  }, []);

  useEffect(() => {
    if (isVisible) {
      document.querySelector("#form_container").style.filter = "blur(10px)";
    } else {
      document.querySelector("#form_container").style.filter = "blur(0px)";
    }
  }, [isVisible]);

  function getAnswersFromLocalStorage() {
    var lsState = tools.getState("FormRDSP", {});
    if (lsState && lsState.answers && lsState.step) {
      setIsVisible(true);
    }
  }

  function loadAnswersFromLS(isLoad = true) {
    if (isLoad) {
      var lsState = tools.getState("FormRDSP", {});

      setAnswers(lsState.answers);
      setStep(lsState.step);
    }
    setIsVisible(false);
  }
  return (
    <div
      style={{
        display: isVisible ? "flex" : "none"
      }}
      className="load_ls_overlay"
    >
      <div className="card animated fadeInDown">
        <div className="card-body">
          <button className="btn btn-primary w-100" onClick={loadAnswersFromLS}>
            Reprendre le formulaire là ou je me suis arrêté
          </button>
          <button
            className="btn btn-default w-100"
            onClick={() => loadAnswersFromLS(false)}
          >
            Non merci
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoadLSOverlay;
