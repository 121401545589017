import React, { useEffect } from "react";
import useStore from "../../context/useStore";
import { Route, Redirect } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Login from "../pages/Login/Login";
import RenewPasswordLoggedIn from "../pages/RenewPassword/RenewPasswordLoggedIn";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [state, dispatch] = useStore();

  return state.auth.isLoading ? (
    <ClipLoader />
  ) : (
    <Route
      {...rest}
      render={(props) =>
        state.auth.isAuthenticated === true ? (
          state.auth.user.mustRenewPassword ? (
            <RenewPasswordLoggedIn />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Login />
        )
      }
    />
  );
};

export default PrivateRoute;
