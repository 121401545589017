import React from "react";

const LandingPE = () => {
  return (
    <div className="col-12 col-lg-6 col-md-6 m-auto px-0 text-center">
      <div className="card">
        <div className="card-body">
          <img src="/assets/logo-pe.png" alt="logo-france-travail" />
          <p className="mt-3">
            Vous allez bénéficier d'un accompagnement personnalisé de France
            Travail.
          </p>
          <p>Nous vous invitons à vous inscrire sur le lien ci-joint.</p>
          <a
            href="https://www.francetravail.fr/"
            target="_BLANK"
            className="btn btn-primary mb-3"
            rel="noreferrer"
          >
            S'inscrire sur France Travail
          </a>
          <p className="text-danger">
            Il est important de vous rendre au rendez-vous qui vous sera proposé
            pour maintenir le versement de votre RSA.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingPE;
