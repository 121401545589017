import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import tools from "../../../helpers/tools";
import useRecipientData from "../../../hooks/useRecipientData/useRecipientData";

const Paragraph = ({ children }) => {
  var styles = StyleSheet.create({
    text: {
      fontSize: 9,
    },
  });

  return <Text style={{ ...styles.text }}>{children}</Text>;
};

const LegalText = () => {
  const { recipient } = useRecipientData();

  var styles = StyleSheet.create({
    container: {
      paddingTop: 10,
      paddingBottom: 10,
    },
    text: {
      fontSize: 9,
    },
  });

  const isFemale = recipient.gender == 2;

  return (
    <View style={{ ...styles.container }}>
      <Paragraph>
        Je sousigné{isFemale ? "e" : ""}, {tools.getFullname(recipient)} m'engage à réaliser les
        actions définies dans le présent contrat et à me rendre disponible pour les rendez-vous et
        actions proposées.
      </Paragraph>
      <Paragraph>
        Le non-respect de cet engagement, sauf motif légitime, m'expose à une réduction ou
        suspension du versement de mon allocation RSA conformément à l'article L.262-37 du Code de
        l'Action Sociale et des Familles (CASF) et après avis de l'équipe pluridisciplinaire (R
        262-70 du CASF).
      </Paragraph>
      <Paragraph>
        Je sousigné(e) {recipient.userManagerDisplayed?.fullName || "..."}, référent(e) unique,
        m'engage à accompagner {tools.getFullname(recipient)} dans son projet d'insertion sociale et
        ou socio-professionnelle.
      </Paragraph>
      <Paragraph>
        Les objectifs du contrat peuvent être révisés à la demande de l'une des deux parties, en cas
        de changement de situation.
      </Paragraph>
    </View>
  );
};

export default LegalText;
