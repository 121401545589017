import React from "react";

const Block = (props) => {
  return (
    <div
      className={`${props.colSize ? props.colSize : "col-12"}  my-3 animated ${
        props.appearEffect ? props.appearEffect : "fadeInUp"
      } faster `}
      style={{
        animationDelay: props.delay + "ms",
      }}
    >
      <div
        className="d-flex align-items-center justify-content-center flex-column p-4 "
        style={{
          // background: "rgb(236, 236, 236)",
          borderRadius: 8,
        }}
      >
        <h4 className="text-center">{props.title}</h4>
        {props.children}
      </div>
    </div>
  );
};

export default Block;
