import React from "react";
import tools from "../../../../helpers/tools";
import InfoBlock from "./InfoBlock";

const UserInfosDisplay = ({ userData }) => {
  return (
    <div className="w-100 mb-2">
      <InfoBlock>
        <i className="fa fa-user mr-2" />
        <div>{tools.getFullname(userData)}</div>
      </InfoBlock>
      <InfoBlock>
        <i className="fa fa-at mr-2" />
        <div>{userData.email}</div>
      </InfoBlock>
      <InfoBlock>
        <i className="fa fa-home mr-2" />

        <div>{tools.getFullAddress(userData.location)}</div>
      </InfoBlock>
    </div>
  );
};

export default UserInfosDisplay;
