import React from "react";
import { Link } from "react-router-dom";

const Page = ({
  container = "container",
  containerClassname = "",
  title,
  children,
  errors = null,
  back = null,
  action = null,
  style = {},
}) => {
  return (
    <div className={container + " my-5 " + containerClassname} style={style}>
      <div className=" d-flex align-items-center mb-4">
        {back && (
          <Link to={back} className="btn" style={{ padding: "15px 18px" }}>
            <i className="fa fa-chevron-left"></i>
          </Link>
        )}

        <h1
          className="mb-0 mx-3 w-100 text-center"
          style={{ fontSize: "1.5rem" }}
        >
          {title}
          {action && (
            <Link to={action.to} className="btn btn-primary float-right ml-3">
              <i className="fa fa-plus mr-2"></i>
              {action.text}
            </Link>
          )}
        </h1>
      </div>
      {errors && errors.other && (
        <div className="alert alert-danger">{errors.other}</div>
      )}
      {children}
    </div>
  );
};

export default Page;
